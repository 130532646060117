import { LineChartRecharts } from '.';
import { FC } from 'react';
import { StackedBarChartRecharts } from './StackedBarChartRecharts';
import { BarChartRecharts } from './BarChartRecharts';
import { ComposedChartRecharts } from './ComposedChartRecharts';
import { PieChartRecharts } from './PieChartRecharts';
import { HrStackedBarChartRecharts } from './HrStackedBarChartRecharts';
import { ComboChartRecharts } from './ComboChartRecharts';
import { HrComboChartRecharts } from './HrComboChartRecharts';
import { PercentageChart } from './PercentageChart';
import { StackedComboChart } from './StackedComboChart';
import { BubbleChartRecharts } from './BubbleChartRecharts';

interface ChartProps {
  chartref?: any;
  data: any;
  keys: any;
  chartMetadata: any;
  showLabel?: boolean;
  selectedTeam?: string;
  hideBtn?: boolean;
  slant?: boolean;
  granularSlant?: boolean;
  forDetails?: boolean;
  ticketType?: string;
  selected?: any;
  queryKey?: string;
  repos?: string[];
  showYLabel?: boolean;
}

export const Chart: FC<ChartProps> = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  granularSlant,
  forDetails,
  ticketType,
  selected,
  queryKey,
  repos,
  showYLabel,
}) => {
  switch (chartMetadata?.chartType) {
    case 'line_chart':
    case 'breakdown_stacked_bar_chart':
      return (
        <LineChartRecharts
          chartref={chartref}
          data={data}
          keys={keys}
          chartMetadata={chartMetadata}
          showLabel={showLabel}
          selected={selected}
          selectedTeam={selectedTeam || ''}
          hideBtn={hideBtn}
          slant={slant}
          granularSlant={granularSlant}
          ticketType={ticketType}
          forDetails={forDetails}
          queryKey={queryKey}
          repos={repos}
          showYLabel={showYLabel}
        />
      );

    case 'bar_chart':
      return (
        <BarChartRecharts
          data={data}
          keys={keys}
          showLabel={showLabel}
          chartMetadata={chartMetadata}
          slant={slant}
          selected={selected}
          granularSlant={granularSlant}
          selectedTeam={selectedTeam}
          repos={repos}
          type={ticketType}
          showYLabel={showYLabel}
        />
      );

    // case 'area_chart':
    //   return (
    //     <AreaChartRecharts
    //       data={data}
    //       keys={keys}
    //       chartMetaData={chartMetaData}
    //     />
    //   );

    // case 'scatter_chart':
    //   return (
    //     <ScatterChartRecharts
    //       data={data}
    //       keys={keys}
    //       chartMetaData={chartMetaData}
    //     />
    //   );

    case 'stacked_bar_chart':
      return (
        <StackedBarChartRecharts
          data={data}
          keys={keys}
          chartMetadata={chartMetadata}
          selected={selected}
          repos={repos}
          selectedTeam={selectedTeam || ''}
          slant={slant}
          granularSlant={granularSlant}
        />
      );

    case 'stacked_bar_and_line_chart':
      return (
        <StackedComboChart
          data={data}
          keys={keys}
          showLabel={true}
          selected={selected}
          granularSlant={granularSlant}
          chartMetadata={chartMetadata}
          selectedTeam={selectedTeam || ''}
          forDetails={forDetails}
          repos={repos}
          showYLabel={showYLabel}
        />
      );

    case 'combo_chart':
      return (
        <ComboChartRecharts
          data={data}
          keys={keys}
          showLabel={true}
          chartMetadata={chartMetadata}
          selectedTeam={selectedTeam || ''}
          forDetails={forDetails}
          hideBtn={true}
          selected={selected}
          granularSlant={granularSlant}
          ticketType={ticketType}
          queryKey={queryKey}
          repos={repos}
        />
      );
    case 'bar_and_line_chart':
      return (
        <ComposedChartRecharts
          data={data}
          keys={keys}
          showLabel={true}
          selected={selected}
          chartMetadata={chartMetadata}
          selectedTeam={selectedTeam || ''}
          granularSlant={granularSlant}
          forDetails={forDetails}
          ticketType={ticketType}
          repos={repos}
          queryKey={queryKey}
        />
      );

    case 'reverse_combo_chart':
      return (
        <HrComboChartRecharts
          data={data}
          keys={keys}
          showLabel={true}
          selected={selected}
          chartMetadata={chartMetadata}
          selectedTeam={selectedTeam || ''}
          forDetails={forDetails}
          ticketType={ticketType}
          repos={repos}
        />
      );

    case 'horizontal_stacked_bar_chart':
      return (
        <HrStackedBarChartRecharts
          data={data}
          keys={keys}
          chartMetadata={chartMetadata}
          forDetails={forDetails}
          selected={selected}
          selectedTeam={selectedTeam || ''}
          hideBtn={true}
          ticketType={ticketType}
          queryKey={queryKey}
          repos={repos}
        />
      );

    case 'pie_chart':
      return (
        <PieChartRecharts
          data={
            chartMetadata?.chartKey === 'ISSUE_PROGRESSION_RATE'
              ? data?.statusDistribution?.map((item: any) => ({
                  ...item,
                  name: item.label,
                  value: item.value ? Number(item.value) : 0,
                }))
              : data
          }
          keys={keys}
          showLabel={showLabel}
          selected={selected}
          chartMetadata={chartMetadata}
          selectedTeam={selectedTeam || ''}
          ticketType={ticketType}
          queryKey={queryKey}
          repos={repos}
        />
      );
    case 'bubble_chart':
      return (
        <BubbleChartRecharts
          data={data}
          keys={keys}
          showLabel={showLabel}
          selected={selected}
          chartMetadata={chartMetadata}
          selectedTeam={selectedTeam || ''}
          ticketType={ticketType}
          queryKey={queryKey}
          repos={repos}
        />
      );

    case 'percentage_chart':
      return (
        <PercentageChart
          data={data}
          keys={keys}
          showLabel={showLabel}
          selected={selected}
          chartMetadata={chartMetadata}
          selectedTeam={selectedTeam || ''}
          repos={repos}
        />
      );

    default:
      return <div />;
  }
};

export default Chart;
