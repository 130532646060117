import {
  Avatar,
  Box,
  Divider,
  Flex,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { Fragment, useCallback, useState } from 'react';
import { usePrChartsGraphql } from '../../../../graphql/index';
import { useTeamDetailsStore } from '../../../../store/teamDetail';
import { Paginate, PeriodType, SlideBar } from '@devd-client/devd/components';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface ShowPrSideDrawerProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  heading: string;
  subHeading: string;
  submitter: string;
  reviewer: string;
  teamId: string;
  selected: PeriodType;
}

const formatLabel = (label: any) => {
  return label
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str: any) => str.toUpperCase())
    .replace('Pr', 'PR');
};

const ShowPrSideDrawer: React.FC<ShowPrSideDrawerProps> = ({
  openDrawer,
  handleDrawerClose,
  heading,
  subHeading,
  submitter,
  reviewer,
  teamId,
  selected,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { data: prData, isFetching: isChartPrFetching } = usePrChartsGraphql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    'PR_REVIEW',
    teamId,
    '',
    '',
    submitter,
    reviewer,
    currentPage,
    100
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  return (
    <SlideBar
      width={'460px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={heading}
      sideBarSubHeading={
        <Text
          fontFamily="heading"
          fontSize="sm"
          color={'text.secondary'}
          fontWeight={'medium'}
        >
          {subHeading}
        </Text>
      }
      content={
        <Stack spacing={1}>
          {isChartPrFetching &&
            Array.from(Array(8).keys()).map((_: any, index: number) => (
              <Skeleton key={index} height={'100px'} width={'100%'} />
            ))}

          {prData?.data?.map((item: any, idx: number, arr: any) => (
            <Fragment key={item?.id}>
              <Box p={2}>
                <Stack spacing={1}>
                  <Box>
                    <Box>
                      {item?.createdAt && (
                        <Text
                          fontFamily="heading"
                          fontSize="xs"
                          color="text.secondary"
                          fontWeight="semibold"
                        >
                          {moment(item?.createdAt).format('lll')}
                        </Text>
                      )}
                      <Link to={`/timeline/pr/${item?.id}`}>
                        <Text
                          fontSize="md"
                          fontFamily="heading"
                          textDecoration="underline"
                          color="gray.700"
                          fontWeight="medium"
                        >
                          {item?.summary}
                        </Text>
                      </Link>
                      {item?.author && (
                        <Flex align="center">
                          <Avatar
                            size="xs"
                            bg="text.secondary"
                            opacity={0.8}
                            name={item?.author?.toUpperCase()?.charAt(0)}
                          />
                          <Text
                            fontSize="sm"
                            fontFamily="heading"
                            color="text.secondary"
                            fontWeight="medium"
                            ml={1}
                          >
                            {item?.author}
                          </Text>
                        </Flex>
                      )}
                    </Box>

                    <Flex align="center" flexWrap="wrap">
                      {Object.entries(item).map(([key, value]: any) => {
                        if (
                          key !== 'summary' &&
                          key !== 'link' &&
                          key !== 'author' &&
                          key !== 'addition' &&
                          key !== 'deletions' &&
                          key !== 'createdAt'
                        ) {
                          if (key === 'updatedAt') {
                            value = moment(value).format('lll');
                          }
                          return (
                            <Flex mr={3} key={key}>
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="semibold"
                                color="gray.600"
                                whiteSpace="nowrap"
                                mr={1}
                              >
                                {formatLabel(key)}
                              </Text>
                              <Text mt={'-3px'}>:</Text>
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="medium"
                                color="text.secondary"
                                ml={1}
                              >
                                {value}
                              </Text>
                            </Flex>
                          );
                        }
                        if (key === 'addition') {
                          return (
                            <Flex
                              mr={3}
                              key={`code-changes`}
                              align="flex-start"
                            >
                              <Text
                                fontFamily="heading"
                                fontSize="sm"
                                fontWeight="semibold"
                                color="gray.600"
                                whiteSpace={'nowrap'}
                                mr={1}
                              >
                                Code Changes
                              </Text>{' '}
                              <Text mt={'-3px'}>:</Text>
                              <Flex ml={1} gap={1}>
                                <Tooltip
                                  hasArrow
                                  placement="bottom-start"
                                  label={'Additions'}
                                >
                                  <Text
                                    color="success"
                                    fontFamily="heading"
                                    fontSize="sm"
                                    fontWeight="medium"
                                  >
                                    +{item?.addition ?? '0'}
                                  </Text>
                                </Tooltip>
                                <Tooltip
                                  hasArrow
                                  placement="bottom-end"
                                  label="Deletions"
                                >
                                  <Text
                                    color="error"
                                    fontFamily="heading"
                                    fontSize="sm"
                                    fontWeight="medium"
                                  >
                                    -{item?.deletions ?? '0'}
                                  </Text>
                                </Tooltip>
                              </Flex>
                            </Flex>
                          );
                        }
                        return null;
                      })}
                    </Flex>
                  </Box>
                </Stack>
              </Box>
              {idx !== arr.length - 1 && <Divider />}
            </Fragment>
          ))}

          <Box marginLeft="-20px" my={'16px'}>
            {prData?.data?.length > 1 && (
              <Paginate
                currentPage={prData?.currentPage + 1}
                totalPageCount={prData?.totalPages}
                pageSize={20}
                onPageChange={(page) => handlePageClick(page as number)}
              />
            )}
          </Box>
        </Stack>
      }
    />
  );
};

export default ShowPrSideDrawer;
