import { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Skeleton,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { Link as NavLink } from 'react-router-dom';
import {
  PeriodType,
  DownArrowIcon,
  UpArrowIcon,
  AiAssistantIcon,
  useAiAssistantStore,
} from '@devd-client/devd/components';
import {
  beforeStyles,
  hoverBeforeStyles,
} from '../InsightCards/InsightCards.utils';
import { BsArrowRight } from 'react-icons/bs';

type SummaryData = {
  category: string;
  count: number;
  link: string;
  subTitle: string;
  title: string;
};

type StatData = {
  percentChange: number;
  improved: boolean;
};

interface SummaryItemProps {
  data: SummaryData;
  previousPeriodStat?: StatData;
  isLoading: boolean;
  handleDrawerOpen?: any;
  selected?: PeriodType;
  metric?: string;
}

const SummaryItem: React.FC<SummaryItemProps> = ({
  data,
  previousPeriodStat,
  isLoading,
  handleDrawerOpen,
  selected,
  metric,
}) => {
  const { colors } = useTheme();
  const [hovered, setHovered] = useState(false);

  const { toggleChatbox, setData, isOpen } = useAiAssistantStore();

  const handleClick = (data: SummaryData) => {
    const payload = {
      metricName: metric,
      metricCount: data?.count === null ? null : `${data?.count}`,
      metricTrend: previousPeriodStat?.percentChange + ' %',
    };
    setData(payload);
    toggleChatbox();
    // Track the event in posthog
    !isOpen &&
      window.analytics.track('ai_assistant', {
        properties: {
          metricName: `${metric}`,
          orgId: localStorage.getItem('orgId'),
        },
      });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton width="100%" height="116px" borderRadius="4px" />
      ) : (
        <Box
          p={3}
          pl={4}
          height="fit-content"
          width="full"
          borderRadius="4px"
          border="1px solid rgba(0,0,0,0.1)"
          boxShadow="0 2px 7px 0 rgba(0,0,0,.06)"
          transition="all 0.2s"
        >
          <Flex align="flex-start" justify="space-between" pb={'0.5rem'}>
            <Flex align="center" justify="space-between">
              {data ? (
                <Box
                  as={NavLink}
                  to={
                    data?.title === 'Contributors' ||
                    data?.title === 'Agreement Exceptions'
                      ? {
                          pathname: window.location.pathname,
                          search: window.location.search,
                        }
                      : `${data?.link}${
                          data?.link && data?.link.includes('?')
                            ? `&${window.location.href.split('?')[1]}`
                            : `?${window.location.href.split('?')[1]}`
                        }`
                  }
                  cursor="pointer"
                  position="relative"
                  display="flex"
                  alignItems="center"
                  fontSize="sm"
                  _hover={{
                    _before: hoverBeforeStyles,
                  }}
                  _before={beforeStyles}
                  onMouseEnter={() => setHovered((prev) => !prev)}
                  onMouseLeave={() => setHovered((prev) => !prev)}
                  onClick={() => {
                    if (
                      data?.title === 'Contributors' ||
                      data?.title === 'Agreement Exceptions'
                    ) {
                      handleDrawerOpen();
                    }
                  }}
                >
                  <Text
                    fontFamily="heading"
                    fontSize="md"
                    as="span"
                    fontWeight="medium"
                    transition="all 300ms linear"
                    mr={hovered ? 2 : 1}
                    color="text.secondary"
                  >
                    {data?.title}
                  </Text>
                  <BsArrowRight size={16} color={colors.text.secondary} />
                </Box>
              ) : (
                <Box h={'16px'} />
              )}
            </Flex>

            <IconButton
              aria-label="home/open-ai-assistant"
              icon={
                <AiAssistantIcon
                  width="25px"
                  height="25px"
                  color={colors.primary}
                />
              }
              onClick={() => handleClick(data)}
              variant="unstyled"
              size={'xs'}
            />
          </Flex>

          <Flex align="center" justify="space-between">
            <Box>
              <Flex width="full" align="space-between">
                <Text
                  fontSize="4xl"
                  fontFamily="heading"
                  color="text.primary"
                  fontWeight="semibold"
                  mr={2}
                >
                  {data?.count || '-'}
                </Text>
                <Flex align={'end'} mb={3}>
                  <Flex align="center" mr={1}>
                    {typeof previousPeriodStat?.improved !== 'undefined' &&
                      previousPeriodStat?.improved !== null &&
                      previousPeriodStat?.percentChange !== 0 && (
                        <>
                          {data.title === 'Agreement Exceptions' ? (
                            <>
                              {previousPeriodStat?.percentChange < 0 ? (
                                <DownArrowIcon
                                  color={
                                    previousPeriodStat?.improved
                                      ? colors.success
                                      : colors.error
                                  }
                                />
                              ) : (
                                <UpArrowIcon
                                  color={
                                    previousPeriodStat?.improved
                                      ? colors.success
                                      : colors.error
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {previousPeriodStat?.percentChange > 0 ? (
                                <UpArrowIcon
                                  color={
                                    previousPeriodStat?.improved
                                      ? colors.success
                                      : colors.error
                                  }
                                />
                              ) : (
                                <DownArrowIcon
                                  color={
                                    previousPeriodStat?.improved
                                      ? colors.success
                                      : colors.error
                                  }
                                />
                              )}
                            </>
                          )}
                        </>
                      )}

                    {typeof previousPeriodStat?.percentChange !== 'undefined' &&
                      previousPeriodStat?.percentChange !== null && (
                        <>
                          <Text
                            fontWeight="semibold"
                            fontSize="sm"
                            ml={1}
                            color={
                              previousPeriodStat?.percentChange !== 0
                                ? previousPeriodStat?.improved
                                  ? 'success'
                                  : 'error'
                                : 'text.secondary'
                            }
                          >
                            {Math.abs(previousPeriodStat?.percentChange)}%
                          </Text>
                        </>
                      )}
                  </Flex>
                  <Text
                    w={'120px'}
                    fontFamily="heading"
                    fontWeight="medium"
                    fontSize="xs"
                    mx={1}
                    color={colors.text.tertiary}
                  >
                    vs previous period
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default SummaryItem;
