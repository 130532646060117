import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import AgreementCard from './AgreementCard';
import { useGetGoals } from '../../../api';
import { useLocation } from 'react-router';
import { AddMetrics, PeriodType } from '@devd-client/devd/components';
import DetailsSection from './AgreementCard/RightSection/DetailsSection';

interface WorkingAgreementProps {
  selected: PeriodType;
}

const WorkingAgreement = ({ selected }: WorkingAgreementProps) => {
  const { pathname } = useLocation();
  const { data, isFetching } = useGetGoals(
    decodeURIComponent(pathname.split('/')[2])
  );

  return (
    <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={6}>
      {data?.dto
        ?.filter((item: any) => item.enabled === true)
        ?.map((agreement: any) => (
          <AgreementCard
            key={agreement.name}
            agreement={agreement}
            isLoading={isFetching}
            selected={selected}
          />
        ))}
      {!isFetching && (
        <AddMetrics
          title="Add Working Agreement"
          modalHeading="Add Working Agreement"
          hideBtn
          hideCreateMetricBtn
          bodyContent={
            data?.dto?.filter((item: any) => item.enabled === false).length <=
            0 ? (
              <Flex justify="center" align="center" height="480px" w="100%">
                <Text fontSize="lg" fontWeight="semibold" color="text.primary">
                  All Working Agreements are active.
                </Text>
              </Flex>
            ) : (
              <SimpleGrid gap={6} columns={{ base: 3 }}>
                {data?.dto
                  ?.filter((item: any) => item.enabled === false)
                  ?.map((agreement: any) => (
                    <DetailsSection
                      insideModal
                      key={agreement.name}
                      agreement={agreement}
                      selectedTeam={{
                        value: decodeURIComponent(pathname.split('/')[2]),
                        label: decodeURIComponent(pathname.split('/')[2]),
                      }}
                    />
                  ))}
              </SimpleGrid>
            )
          }
        />
      )}
    </SimpleGrid>
  );
};

export default WorkingAgreement;
