import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import {
  DateFilter,
  durationData,
  ISelect,
  Loader,
  PeriodType,
  removeEmptyQueryParams,
  TeamMenu,
  useQueryState,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useReportQuestionDetailsGql } from '../../graphql';
import ReportItem from './ReportItem';
import { useSearchParams } from 'react-router-dom';
import { getQuestionIcon } from '../../helpers/report.utils';
import { useReportsStore } from '../../store/index';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';

interface DetailViewProps {
  selectedItem: any;
}

const Divider = styled.hr`
  border-top: ${({ theme }) => `1.6px solid #ccc`};
  color: transparent;
`;

const DetailView: FC<DetailViewProps> = ({ selectedItem }) => {
  const { setZeroStates } = useReportsStore();
  const [searchParam] = useSearchParams();
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [appState, dispatch] = useAuth();
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');

  const [selectedTeam, setSelectedTeam] = useState<ISelect>({
    value: appState?.user?.dto?.team ? appState?.user?.dto?.team : '',
    label: appState?.user?.dto?.team ? appState?.user?.dto?.team : 'All',
  });

  const id = searchParam.get('id');
  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const [selected, setSelected] = useState<PeriodType>({
    id: 0,
    startDate: '',
    endDate: '',
    duration: '',
    sprintId: '',
  });

  const { data: reportDetails, isLoading: reportDetailsLoading } =
    useReportQuestionDetailsGql(
      id as string,
      selected.startDate,
      selected.endDate,
      selected?.sprintId
    );

  useEffect(() => {
    if (!reportDetails) return;
    setZeroStates(reportDetails?.metadata?.zeroStates);
  }, [reportDetails]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'All'
        ? setTeam('All')
        : setTeam(selectedTeam.value);
    }
  }, [selected, selectedTeam.value]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'All'
          ? { value: '', label: 'All' }
          : { value: team, label: team }
      );

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <>
      <Stack spacing={4} w="full">
        <Flex w="full" align="flex-end" justify="space-between">
          <Flex align="center">
            {getQuestionIcon(selectedItem?.icon)}
            <Text
              fontFamily="heading"
              fontSize="md"
              ml={2}
              fontWeight="semibold"
              color="text.primary"
            >
              {selectedItem?.question}
            </Text>
          </Flex>
        </Flex>
        <Divider />

        <Flex>
          {!!id && (
            <Flex w="full" align="flex-end" justify="space-between">
              <Box>
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              </Box>
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            </Flex>
          )}
        </Flex>

        {reportDetails?.reports?.map(
          (report: any, idx: number, reportArray: any[]) => (
            <ReportItem
              key={`report-${idx}`}
              report={report}
              showDivider={reportArray.length - 1 !== idx}
              selected={selected}
              selectedTeam={selectedTeam}
            />
          )
        )}
      </Stack>
      {reportDetailsLoading && <Loader />}
    </>
  );
};

export default DetailView;
