import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  doraThresholdsGql,
  getContributorDetailGraphql,
  getContributorGraphql,
  getHomePageMetricByTypeGraphql,
  getInsightCardDataGraphql,
  getSummaryByTypeGraphql,
  getWorkingAgreementDetails,
  gitThresholdsGql,
  issueThresholdsGql,
  qualityThresholdsGql,
  sprintMetricGql,
  customMetricGraphql,
} from './query';
import { getTeamListGraphql } from '@devd-client/devd/team-details';

export const QUERY_KEYS = {
  summaryGql: 'summaryGql',
  insightCardData: 'insightCardData',
  insightMetric: 'insightMetric',
  contributorDetail: 'contributorDetail',
  contributorGql: 'contributorGql',
  allIssueThreshold: 'allIssueThreshold',
  allGitThreshold: 'allGitThreshold',
  allDoraThreshold: 'allDoraThreshold',
  allQualityThresholds: 'allQualityThresholds',
  customMetric: 'customMetric',
};

export const useSummaryGql = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.summaryGql, type, teamId, startDate, endDate, sprintId],
    () => getSummaryByTypeGraphql(type, teamId, startDate, endDate, sprintId),
    {
      select: (data) => data?.HomePageSummaryDetailByType,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useInsightCardDataGql = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.insightCardData, type, teamId, startDate, endDate, sprintId],
    () => getInsightCardDataGraphql(type, teamId, startDate, endDate, sprintId),
    {
      select: (data) => data?.HomePageInsightCardData,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useInsightMetricGql = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    [QUERY_KEYS.insightMetric, type, teamId, startDate, endDate, sprintId],
    () =>
      getHomePageMetricByTypeGraphql(
        type,
        teamId,
        startDate,
        endDate,
        sprintId
      ),
    {
      select: (data) => {
        return {
          ...data.HomePageMetricByType.chart,
          data: JSON.parse(data?.HomePageMetricByType?.chart?.data),
          insights: data.HomePageMetricByType.insights,
          link: data.HomePageMetricByType.link,
        };
      },

      onSuccess: () => {
        queryClient.prefetchQuery(
          ['teamList', '', startDate, endDate, sprintId, teamId, '', 0, 10],
          () =>
            getTeamListGraphql(
              '',
              startDate,
              endDate,
              sprintId,
              teamId,
              0,
              10,
              ''
            )
        );
      },

      enabled: !!startDate && !!endDate,
    }
  );
};

export const useBurnoutContributorDetail = (
  type: string,
  teamId: string,
  startDate: string,
  endDate: string,
  sprintId: string
) => {
  return useQuery(
    [QUERY_KEYS.contributorDetail, type, teamId, startDate, endDate, sprintId],
    () =>
      getContributorDetailGraphql(type, teamId, startDate, endDate, sprintId),
    {
      select: (data) =>
        JSON.parse(data?.HomePageMetricByTypeDetails?.chart?.data),
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useContributorDetailGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  role: string | null
) => {
  return useQuery(
    [QUERY_KEYS.contributorGql, startDate, endDate, sprintId, teamId],
    () => getContributorGraphql(startDate, endDate, sprintId, teamId),
    {
      select: (data) => data?.ContributorDetails,
      enabled: !!startDate && !!endDate && role !== 'EMPLOYEE',
    }
  );
};

export const useWorkingAgreementDetailGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
) => {
  return useQuery(
    [
      QUERY_KEYS.contributorGql,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    ],
    () =>
      getWorkingAgreementDetails(
        startDate,
        endDate,
        sprintId,
        teamId,
        pageNumber,
        pageSize
      ),
    {
      select: (data) => data?.WorkingAgreementDetails,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useTicketCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  repos: string[],
  ticketType: string
) => {
  return useQuery(
    [
      QUERY_KEYS.allIssueThreshold,
      startDate,
      endDate,
      sprintId,
      teamId,
      repos,
      ticketType,
    ],
    () =>
      issueThresholdsGql(
        startDate,
        endDate,
        sprintId,
        teamId,
        repos,
        ticketType
      ),
    {
      select: (data) => data?.AllIssueThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useQualityCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  repos: string[]
) => {
  return useQuery(
    [
      QUERY_KEYS.allQualityThresholds,
      startDate,
      endDate,
      sprintId,
      teamId,
      repos,
    ],
    () => qualityThresholdsGql(startDate, endDate, sprintId, teamId, repos),
    {
      select: (data) => data?.AllQualityThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useGitCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  repos: string[]
) => {
  return useQuery(
    [QUERY_KEYS.allGitThreshold, startDate, endDate, sprintId, teamId, repos],
    () => gitThresholdsGql(startDate, endDate, sprintId, teamId, repos),
    {
      select: (data) => data?.AllGitThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useDoraCards = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string
) => {
  return useQuery(
    [QUERY_KEYS.allDoraThreshold, startDate, endDate, sprintId, teamId],
    () => doraThresholdsGql(startDate, endDate, sprintId, teamId),
    {
      select: (data) => data?.AllDoraThresholds,
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};

export const useSprintList = (
  startDate: string,
  endDate: string,
  teamId: string,
  sprintId: string,
  sprintIds: string[],
  pageSize: number,
  pageNumber: number,
  bySp: boolean,
  status: string,
  type: string
) => {
  return useQuery(
    [
      QUERY_KEYS.allDoraThreshold,
      startDate,
      endDate,
      pageSize,
      pageNumber,
      teamId,
      sprintId,
      sprintIds,
      bySp,
      status,
      type,
    ],
    () =>
      sprintMetricGql(
        startDate,
        endDate,
        teamId,
        sprintId,
        sprintIds,
        pageSize,
        pageNumber,
        bySp,
        status,
        type
      ),
    {
      select: (data) => {
        return {
          ...data?.SprintMetricsWithStatus,
          reports: data?.SprintMetricsWithStatus?.reports?.map((el: any) => ({
            ...el,
            sprintVelocity: {
              chart: JSON.parse(el.sprintVelocity.chart),
            },
          })),
        };
      },
      enabled: !!startDate && !!endDate,
      keepPreviousData: true,
      staleTime: 300000,
    }
  );
};

export const useCustomMetrics = (
  startDate: string,
  endDate: string,
  metricType: string,
  breakdown: string,
  selectedTeam: string,
  sprintId: string,
  granularity: string
) => {
  return useQuery(
    [
      QUERY_KEYS.customMetric,
      startDate,
      endDate,
      metricType,
      breakdown,
      selectedTeam,
      sprintId,
      granularity,
    ],
    () =>
      customMetricGraphql(
        startDate,
        endDate,
        metricType,
        selectedTeam,
        sprintId,
        granularity
      ),
    {
      select: (data) => ({
        ...data.CustomMetrics,
        data: JSON.parse(data.CustomMetrics.data),
      }),
      enabled: !!metricType && !!startDate && !!endDate,
      keepPreviousData: true,
    }
  );
};
