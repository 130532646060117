import styled from 'styled-components';

type ButtonType = 'primary' | 'secondary';

type CardButtonType = {
  mb?: string;
  variant: ButtonType;
};

type ValueType = {
  color?: string;
};
type labelType = {
  color?: string;
};

type CountProps = {
  color?: string;
};

export const MetricCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 16px;
  min-height: 400px;
  height: 100%;
  min-width: 320px;
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const DetailBox = styled.div`
  margin-bottom: 20px;
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TagLabel = styled.p<labelType>`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  letter-spacing: 0.5px;
  padding: 4px 6px;
  color: ${({ theme }) => theme.colors.success};
  background-color: ${({ color }) => color || '#f0fff4'};
  margin-right: 5px;
`;

export const TagLabels = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ChartNameWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: ${({ mt }) => mt};
`;

export const Name = styled.h5`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 12px;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-right: 5px;
`;

export const IconWrapper = styled.div``;

export const MetricLabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
`;

export const Count = styled.h1<CountProps>`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes['4xl']};
  margin-right: 8px;
  line-height: 28px;
  color: ${({ color }) => color};
`;

export const Label = styled.h5`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 12px;
  letter-spacing: 0.5px;
`;

export const Value = styled.p<ValueType>`
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ color }) => color || '#777'};
  line-height: 16px;
`;

export const ChartBox = styled.div`
  width: 100%;
  height: 160px;
`;

export const ButtonBox = styled.div`
  min-height: 20px;
`;

export const beforeStyles = {
  content: '""',
  position: 'absolute',
  display: 'block',
  width: '100%',
  height: '1.5px',
  bottom: '0',
  left: '0',
  backgroundColor: 'text.secondary',
  transform: 'scaleX(0)',
  transformOrigin: 'top left',
  transition: 'transform 0.3s ease',
};

export const hoverBeforeStyles = {
  transform: 'scaleX(1)',
};
