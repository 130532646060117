import { Box, Text, Center } from '@chakra-ui/react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SummaryItem from './SummaryItem';
import { useSummaryGql } from '../../graphql';
import './summary.styles.css';
import SlideDrawer from './SlideDrawer';
import SlideAgreementDrawer from './SlideAgreementDrawer';
import { useCallback, useState } from 'react';
import { PeriodType } from '@devd-client/devd/components';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 2000 },
    items: 6,
    slidesToSlide: 6,
  },
  desktop: {
    breakpoint: { max: 2000, min: 1600 },
    items: 5,
    slidesToSlide: 5,
  },

  tablet: {
    breakpoint: { max: 1600, min: 464 },
    items: 4,
    slidesToSlide: 4,
  },
};

interface SummaryProps {
  team: string;
  selected: PeriodType;
}

export const Summary: React.FC<SummaryProps> = ({ team, selected }) => {
  const { data: taskData, isLoading: taskLoading } = useSummaryGql(
    'task',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const { data: contributorData, isLoading: contributorLoading } =
    useSummaryGql(
      'contributor',
      team,
      selected.startDate,
      selected.endDate,
      selected?.sprintId
    );

  const { data: prData, isLoading: prLoading } = useSummaryGql(
    'pr',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const { data: deploymentData, isLoading: deploymentLoading } = useSummaryGql(
    'deployment',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const { data: goalData, isLoading: goalLoading } = useSummaryGql(
    'goal',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const { data: incidentData, isLoading: incidentLoading } = useSummaryGql(
    'incident',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openAgreementDrawer, setOpenAgreementDrawer] =
    useState<boolean>(false);

  const handleDrawerOpen = useCallback(() => setOpenDrawer(true), [openDrawer]);
  const handleDrawerClose = useCallback(
    () => setOpenDrawer(false),
    [openDrawer]
  );

  const handleAgreementDrawerOpen = useCallback(
    () => setOpenAgreementDrawer(true),
    [openAgreementDrawer]
  );
  const handleAgreementDrawerClose = useCallback(
    () => setOpenAgreementDrawer(false),
    [openAgreementDrawer]
  );

  return (
    <Box mt={6} height="100%">
      <Text
        mb={2}
        color="text.primary"
        fontSize="sm"
        fontWeight="semibold"
        fontFamily="heading"
      >
        {`Discover your team highlights.`}
      </Text>
      <Carousel responsive={responsive}>
        <Center height="120px" mr={6}>
          <SummaryItem
            isLoading={contributorLoading}
            data={contributorData?.summary}
            previousPeriodStat={contributorData?.previousPeriodStat}
            handleDrawerOpen={handleDrawerOpen}
            selected={selected}
            metric="CONTRIBUTOR_COUNT"
          />
        </Center>
        <Center height="120px" mr={6}>
          <SummaryItem
            isLoading={deploymentLoading}
            data={deploymentData?.summary}
            previousPeriodStat={deploymentData?.previousPeriodStat}
            metric="DEPLOYMENT_FREQUENCY"
          />
        </Center>
        <Center height="120px" mr={6}>
          <SummaryItem
            isLoading={goalLoading}
            data={goalData?.summary}
            previousPeriodStat={goalData?.previousPeriodStat}
            handleDrawerOpen={handleAgreementDrawerOpen}
            selected={selected}
            metric="WORKING_AGREEMENT_EXCEPTIONS"
          />
        </Center>
        <Center height="120px" mr={6}>
          <SummaryItem
            isLoading={incidentLoading}
            data={incidentData?.summary}
            previousPeriodStat={incidentData?.previousPeriodStat}
            metric="CHANGE_FAILURE"
          />
        </Center>
        <Center height="120px" mr={6}>
          <SummaryItem
            isLoading={prLoading}
            data={prData?.summary}
            previousPeriodStat={prData?.previousPeriodStat}
            metric="PR_MERGE"
          />
        </Center>
        <Center height="120px" mr={1}>
          <SummaryItem
            isLoading={taskLoading}
            data={taskData?.summary}
            previousPeriodStat={taskData?.previousPeriodStat}
            metric="TASK_CLOSE"
          />
        </Center>
      </Carousel>

      {openDrawer && (
        <SlideDrawer
          openDrawer={openDrawer}
          handleDrawerClose={handleDrawerClose}
          team={team}
          selected={selected}
        />
      )}
      {openAgreementDrawer && (
        <SlideAgreementDrawer
          openAgreementDrawer={openAgreementDrawer}
          handleAgreementDrawerClose={handleAgreementDrawerClose}
          team={team}
          selected={selected}
        />
      )}
    </Box>
  );
};

export default Summary;
