import { Box, Button, Flex, Stack, useDisclosure } from '@chakra-ui/react';
import {
  Breadcrumbs,
  MetricDetails,
  PageHeader,
} from '@devd-client/devd/components';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CustomMetricsList from '../components/customMetrics/CustomMetricsList';
import CustomMetricsDetails from '../components/customMetrics/CustomMetricsDetails';
import CreateDashboardModal from '../components/customMetrics/CreateDashboardModal';

export const CustomDashbaord = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const metricType = searchParams?.get('type');
  const createDashboardModal = useDisclosure();

  const [selectedItem, setSelectedItem] = useState<any>(null);

  return (
    <>
      {!metricType ? (
        <Flex display={'flex'} flexDirection={'column'} width={'100%'}>
          {!id ? (
            <PageHeader
              headingText="Custom Dashboard"
              mb={'24px'}
              component={
                <Button
                  rounded={'4px'}
                  bg={'transparent'}
                  paddingY={'12px'}
                  paddingX={'16px'}
                  border={'1px solid #4D4DFF'}
                  color={'#4D4DFF'}
                  width={'fit-content'}
                  fontSize={'14px'}
                  fontWeight={'500'}
                  _hover={{ bg: 'transparent' }}
                  onClick={createDashboardModal.onOpen}
                  fontFamily={'inter'}
                >
                  Create Dashboard
                </Button>
              }
            />
          ) : (
            <Box mb={2}>
              <Breadcrumbs />
            </Box>
          )}

          <Stack spacing={3} w="full">
            {id ? (
              <CustomMetricsDetails selectedItem={selectedItem} />
            ) : (
              <CustomMetricsList setSelectedItem={setSelectedItem} />
            )}
          </Stack>
        </Flex>
      ) : (
        <MetricDetails />
      )}
      {createDashboardModal.isOpen && (
        <CreateDashboardModal
          isOpen={createDashboardModal.isOpen}
          onClose={createDashboardModal.onClose}
        />
      )}
    </>
  );
};

export default CustomDashbaord;
