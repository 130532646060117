import styled from 'styled-components';

type PageHeaderContainerProps = {
  mt?: string;
  mb?: string;
};

type HeadingProps = {
  color?: string;
};

export const PageHeaderContainer = styled.div<PageHeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ mb }) => mb};
  margin-top: ${({ mt }) => mt};
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  margin-bottom: 4px;
`;

export const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ComponentWrapper = styled.div`
  /* margin-right: 10px; */
`;

export const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const Heading = styled.h1<HeadingProps>`
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ color }) => color || '#333'};
`;

export const SubHeading = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
