import { SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import InsightCard from './InsightCard';
import { useInsightCardDataGql } from '../../graphql';
import DistributionCard from './DistributionCard';
import DoraProgressCard from './DoraProgressCard';
import BurnoutRiskCard from './BurnoutRiskCard';
import { StackCycleTimeCard } from './CycleTimeCard';
import { QualityCard } from './QualityCard';
import { PeriodType } from '@devd-client/devd/components';

interface InsightCardsProps {
  team: string;
  selected: PeriodType;
}

const charts = [
  'LEAD_TIME_DISTRIBUTION',
  'PR_SIZE_DISTRIBUTION',
  'PR_REVIEWER_DISTRIBUTION',
  'WORK_IN_PROGRESS',
];

const InsightCards: React.FC<InsightCardsProps> = ({ team, selected }) => {
  const { data: repos, isLoading: reposLoading } = useInsightCardDataGql(
    'repos',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );
  const { data: burnouts, isLoading: burnoutsLoading } = useInsightCardDataGql(
    'burnouts',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );
  const { data: deployments, isLoading: deploymentsLoading } =
    useInsightCardDataGql(
      'deployments',
      team,
      selected.startDate,
      selected.endDate,
      selected?.sprintId
    );
  const { data: incidents, isLoading: incidentsLoading } =
    useInsightCardDataGql(
      'incidents',
      team,
      selected.startDate,
      selected.endDate,
      selected?.sprintId
    );

  const filteredCharts =
    team === ''
      ? charts.filter((item: string) => item !== 'PR_REVIEWER_DISTRIBUTION')
      : charts;

  return (
    <SimpleGrid columns={3} spacing={6}>
      <DoraProgressCard team={team} selected={selected} />

      <StackCycleTimeCard team={team} selected={selected} />

      <QualityCard team={team} selected={selected} />

      {team !== '' && <BurnoutRiskCard team={team} selected={selected} />}

      {filteredCharts.map((chart: string) => (
        <DistributionCard
          key={chart}
          chart={chart}
          team={team}
          selected={selected}
        />
      ))}

      <InsightCard data={repos} loading={reposLoading} team={team} />
      <InsightCard data={burnouts} loading={burnoutsLoading} team={team} />
      <InsightCard
        data={deployments}
        loading={deploymentsLoading}
        team={team}
      />
      <InsightCard data={incidents} loading={incidentsLoading} team={team} />
    </SimpleGrid>
  );
};

export default InsightCards;
