import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router';
import Loader from '../appLoader';
import { ShowStatusText } from '../showStatusText';
import { useFetchIntegrationDetails } from './api';
import ConfirmModal from './confirmModal/confirmModal';
import {
  Description,
  Divider,
  IconContainer,
  Image,
  IntegrationsDetailsContainer,
  ItemBox,
  ItemName,
  ItemSubText,
  LeftSection,
  RightSection,
  Status,
  TopSection,
} from './helpers/integrationDetails.styled';
import IntegrationModal from './IntegrationModal';
import BottomView from './BottomView/bottomView';
import Breadcrumbs from '../appBreadcrumb';
import TableView from './BottomView/tableView';
import { isHTML } from '../shared/utils';
import ReactHtmlParser from 'html-react-parser';
import { BiHelpCircle } from 'react-icons/bi';
import ConfigTab from './BottomView/ConfigTab';

export const IntegrationDetails = () => {
  const { id } = useParams();

  const { data: intDetailsData, isFetching } = useFetchIntegrationDetails(
    id as string
  );
  const [loading, setLoading] = useState(false);
  const [bodyText, setBodyText] = useState<string>('');
  const [headerText, setHeaderText] = useState<string>('');

  const [openIntegrationModal, setOpenIntegrationModal] =
    useState<boolean>(false);
  const handleIntegrateModalOpen = () => setOpenIntegrationModal(true);
  const handleIntegrateModalClose = () => {
    setOpenIntegrationModal(false);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const handleConfirmModalOpen = () => {
    setLoading(false);
    setOpenConfirmModal(true);
  };

  const handleHelpIconClick = () => {
    window.open(
      intDetailsData?.dto?.links?.helpDoc,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=270,left=800,width=700,height=600'
    );
  };

  return (
    <IntegrationsDetailsContainer>
      <Box mb={3}>
        <Breadcrumbs />
      </Box>
      <TopSection>
        <LeftSection>
          <IconContainer>
            <Image
              src={
                intDetailsData?.dto?.links?.icon ||
                'https://icon-library.com/images/sugar-cube-icon/sugar-cube-icon-10.jpg'
              }
            />
          </IconContainer>
          <ItemBox>
            <Flex alignItems={'center'}>
              <ItemName>{intDetailsData?.dto?.displayName}</ItemName>
              {intDetailsData?.dto?.links?.helpDoc && (
                <Box
                  as={BiHelpCircle}
                  onClick={handleHelpIconClick}
                  _hover={{ cursor: 'pointer', color: 'primary' }}
                  transition="color 0.3s"
                  lineHeight={'29px'}
                  fontSize={'xl'}
                  ml={'2px'}
                />
              )}
            </Flex>

            <ItemSubText>{intDetailsData?.dto?.subTitle}</ItemSubText>
          </ItemBox>
        </LeftSection>

        <RightSection>
          {intDetailsData?.dto?.active ? (
            <Stack align="flex-end">
              <Status>Active</Status>
              <Text
                fontSize="sm"
                fontFamily="heading"
                fontWeight="normal"
                color="text.secondary"
              >
                To deactivate the integration, please contact us on chat
              </Text>

              <ShowStatusText
                status={intDetailsData?.dto?.status}
                message={intDetailsData?.dto?.message}
              />
            </Stack>
          ) : (
            <Stack align="flex-end">
              {intDetailsData?.dto?.displayName && (
                <Button variant="tertiary" onClick={handleIntegrateModalOpen}>
                  {`Integrate ${intDetailsData?.dto?.displayName}`}
                </Button>
              )}

              <ShowStatusText
                status={intDetailsData?.dto?.status}
                message={intDetailsData?.dto?.message}
              />
            </Stack>
          )}
        </RightSection>
      </TopSection>

      <Divider />

      {isHTML(intDetailsData?.dto?.description) ? (
        <Box>{ReactHtmlParser(intDetailsData?.dto?.description)}</Box>
      ) : (
        <Description>{intDetailsData?.dto?.description}</Description>
      )}
      {openIntegrationModal && (
        <IntegrationModal
          isOpen={openIntegrationModal}
          handleIntegrateModalClose={handleIntegrateModalClose}
          intDetailsData={intDetailsData}
          handleConfirmModalOpen={handleConfirmModalOpen}
        />
      )}
      {openIntegrationModal === false && openConfirmModal && (
        <ConfirmModal
          isOpen={openConfirmModal}
          headerText={headerText}
          bodyText={bodyText}
        />
      )}
      {intDetailsData?.dto?.showFieldMap && (
        <BottomView intDetailsData={intDetailsData} />
      )}

      {(intDetailsData?.dto?.name === 'SonarCloud' ||
        intDetailsData?.dto?.name === 'SonarQube') &&
        intDetailsData?.dto?.active && (
          <ConfigTab name={intDetailsData?.dto?.name} />
        )}

      {intDetailsData?.dto?.reSyncProjects && (
        <TableView
          intDetailsData={intDetailsData}
          openIntegrationModal={openIntegrationModal}
          handleConfirmModalOpen={handleConfirmModalOpen}
          handleIntegrateModalClose={handleIntegrateModalClose}
          openConfirmModal={openConfirmModal}
          handleIntegrateModalOpen={handleIntegrateModalOpen}
          bodyText={bodyText}
          headerText={headerText}
        />
      )}

      {(isFetching || loading) && <Loader />}
    </IntegrationsDetailsContainer>
  );
};

export default IntegrationDetails;
