import {
  Box,
  Tooltip,
  Flex,
  Text,
  Stack,
  useTheme,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  AlertTriangle,
  CFR_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  EliteIcon,
  MetricSidebar,
  PeriodType,
  StarIcon,
  Thumsup,
  convertHexToRGBA,
} from '@devd-client/devd/components';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { useLocation } from 'react-router';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import moment from 'moment';

interface DoraChartProps {
  doraChartStats: any;
  selected: PeriodType;
}

const elite = (color: string) => (
  <Flex
    w={'100%'}
    h="24px"
    bg={`linear-gradient(270deg, ${color} 0%, #EEFDD7 100%)`}
  ></Flex>
);

const high = (color: string) => (
  <>
    <Flex
      w={'75%'}
      h="24px"
      bg={`linear-gradient(270deg, ${color} 0%, #D6E4FF 100%)`}
    ></Flex>
    <Box w="25%" h="24px" bg="#E3E6EA" />
  </>
);

const medium = (color: string) => (
  <>
    <Flex
      w={'50%'}
      h="24px"
      bg={`linear-gradient(270deg, ${color} 0%, #FFF6D6 100%)`}
    ></Flex>
    <Box w="50%" h="24px" bg="#E3E6EA" />
  </>
);

const low = (color: string) => (
  <>
    <Flex
      w={'25%'}
      h="24px"
      bg={`linear-gradient(270deg, ${color} 0%, #FFE9D5 100%)`}
    ></Flex>
    <Box w="75%" h="24px" bg="#E3E6EA" />
  </>
);

function convertMinutesToDaysHoursMinutes(minutes: any) {
  const days = Math.floor(minutes / (24 * 60));
  minutes %= 24 * 60;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  let result = '';
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0) {
    result += `${hours}h `;
  }
  result += `${remainingMinutes}m`;

  return result;
}

export const DoraChart: React.FC<DoraChartProps> = ({
  doraChartStats,
  selected,
}) => {
  const { colors } = useTheme();

  const teammodal = useDisclosure();

  const { pathname } = useLocation();

  const [metricsName, setMetricsName] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  return (
    <Flex>
      <Flex direction="row" w={'100%'} justifyContent={'space-around'}>
        <Flex direction="column" w={'100%'} justifyContent={'space-around'}>
          {doraChartStats?.map((data: any, index: number) => (
            <React.Fragment key={index}>
              <Flex align="center" justifyContent="space-between">
                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                  minW={'300px'}
                >
                  <Text
                    fontFamily="inter"
                    fontWeight="semibold"
                    color="gray.500"
                  >
                    {data?.chartMetadata?.chartTitle}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <Tooltip
                        label={`View details`}
                        aria-label={`View details`}
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setHeading(data?.chartMetadata?.chartTitle);
                            teammodal.onOpen();
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color="text.primary"
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.chartMetadata?.chartTitle === 'Lead Time'
                            ? convertMinutesToDaysHoursMinutes(
                                data?.currentData
                              )
                            : data?.currentData}
                        </Text>
                      </Tooltip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <Tooltip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                ${
                                  data?.previousData?.improved > 0
                                    ? 'improved'
                                    : 'deteriorated'
                                } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                <Stack mt={5} mb={5} w="50%">
                  <Flex position="relative" dir="row">
                    {data?.performanceRank === 'Elite' && elite('#71E03A')}
                    {data?.performanceRank === 'High' && high('#0095E6')}
                    {data?.performanceRank === 'Medium' && medium('#FFBB35')}
                    {data?.performanceRank === 'Low' && low('#FF5230')}

                    <Flex
                      position="absolute"
                      height="100%"
                      width="100%"
                      zIndex={1}
                      top={0}
                      left={0}
                    >
                      <Box
                        flex={1}
                        borderRight={'2px solid white'}
                        position="relative"
                      >
                        {data?.performanceRank === 'Low' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA('#FF5230', 0.1)}
                            position="absolute"
                            bottom={-8}
                            right={-7}
                            align="center"
                          >
                            <Box>
                              <AlertTriangle
                                height="12px"
                                width="12px"
                                color={'#FF5230'}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={'#FF5230'}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              Low
                            </Text>
                          </Flex>
                        )}
                      </Box>
                      <Box
                        flex={1}
                        borderRight={'2px solid white'}
                        position="relative"
                      >
                        {data?.performanceRank === 'Medium' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA('#FFBB35', 0.1)}
                            position="absolute"
                            bottom={-8}
                            right={-8}
                            align="center"
                          >
                            <Box>
                              <Thumsup
                                height="12px"
                                width="12px"
                                color={'#FFBB35'}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={'#FFBB35'}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              Medium
                            </Text>
                          </Flex>
                        )}
                      </Box>
                      <Box
                        flex={1}
                        borderRight={'2px solid white'}
                        position="relative"
                      >
                        {data?.performanceRank === 'High' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA('#0095E6', 0.1)}
                            position="absolute"
                            bottom={-8}
                            right={-7}
                            align="center"
                          >
                            <Box>
                              <StarIcon
                                height="12px"
                                width="12px"
                                color={'#0095E6'}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={'#0095E6'}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              High
                            </Text>
                          </Flex>
                        )}
                      </Box>
                      <Box flex={1} position="relative">
                        {data?.performanceRank === 'Elite' && (
                          <Flex
                            p={'4px 6px'}
                            bg={convertHexToRGBA('#71E03A', 0.1)}
                            position="absolute"
                            bottom={-8}
                            right={0}
                            align="center"
                          >
                            <Box>
                              <EliteIcon
                                height="12px"
                                width="12px"
                                color={'#71E03A'}
                              />
                            </Box>
                            <Text
                              ml={1}
                              color={'#71E03A'}
                              fontSize="xs"
                              fontWeight="medium"
                              fontFamily="heading"
                            >
                              Elite
                            </Text>
                          </Flex>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                </Stack>
              </Flex>
            </React.Fragment>
          ))}
        </Flex>
      </Flex>

      {[...CFR_CHART_KEYS, ...DEPLOY_CHART_KEYS].includes(metricsName) &&
        teammodal.isOpen && (
          <MetricSidebar
            openDrawer={teammodal.isOpen}
            handleDrawerClose={teammodal.onClose}
            heading={heading}
            subHeading={`${moment(selected.startDate).format(
              'DD MMM'
            )} - ${moment(selected.endDate).format('DD MMM')}
           `}
            payload={{
              payload: {
                startDate: selected.startDate,
                endDate: selected.endDate,
              },
            }}
            currentChartMeta={{ chartKey: metricsName }}
            filterBy={{ teamId: decodeURIComponent(pathname?.split('/')[2]) }}
            selectedTeam={decodeURIComponent(pathname?.split('/')[2])}
          />
        )}
    </Flex>
  );
};

export default DoraChart;
