import { durationData, PeriodType } from '@devd-client/devd/components';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

//TODO: Need to add the team object coming from backend
// type TeamList = {};

interface TeamDetailsState {
  memberCount: number;
  setMemberCount: (membercount: number) => void;
}

export const useTeamDetailsStore = create<TeamDetailsState>()(
  persist(
    (set) => ({
      memberCount: 0,
      setMemberCount: (memberCount: number) => set({ memberCount }),
    }),
    {
      name: 'team-details-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
