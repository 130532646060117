import {
  Box,
  Grid,
  GridItem,
  Skeleton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import {
  PageHeader,
  PageToolbar,
  TeamMenu,
  durationData,
  useQueryState,
  MetaTags,
  MetricInfoItems,
  MetricDashboard,
  useAiAssistantStore,
  removeEmptyQueryParams,
  Loader,
  DateFilter,
} from '@devd-client/devd/components';
import { Select } from 'chakra-react-select';

import { FC, useEffect, useState } from 'react';
import { Chatbox } from '../components/Chatbox';
import { QUERY_KEYS, useTicketCards } from '../graphql';
import moment from 'moment';
import { ticketTypeFilterOptions } from '../helpers/home.utils';
import { useFetchRepo } from '../api';
import { useDashboardStore } from '@devd-client/devd/components';

const filterOptions = [
  { value: 'team', label: 'Team' },
  { value: 'project', label: 'Project' },
];

export const Ticket: FC = () => {
  const [isLargerThan1820] = useMediaQuery('(min-width: 1820px)');
  const [isLargeThan2120] = useMediaQuery('(min-width: 2120px)');

  const { data: repoList, isLoading: repoLoading } = useFetchRepo('jira');
  const [filterBy, setFilterBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'team', label: 'Team' });
  const [repos, setRepos] = useState([]);

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [ticket, setTicket] = useQueryState('ticket');
  const [metricType, setMetricType] = useQueryState('tab');
  const [appState, dispatch] = useAuth();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  const [type, setType] = useState<string>();

  const [ticketType, setTicketType] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [clickedTab, setClickedTab] = useState<string>();

  const { data, isFetching } = useTicketCards(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    filterBy.value === 'project' ? '' : selectedTeam.value,
    filterBy.value === 'team' ? [] : repos.map((el: any) => el.value),
    ticketType?.length === 1 ? ticketType[0].value : 'all'
  );

  useEffect(() => {
    if (!data) return;
    if (!clickedTab && !type) {
      setClickedTab(data[0]?.chartKey);
      setType(data[0]?.chartKey);
    }
  }, [data]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (ticketType) {
      ticketType?.length > 1
        ? setTicket('all')
        : setTicket(ticketType[0]?.value);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'All'
        ? setTeam('All')
        : setTeam(selectedTeam.value);
    }

    if (type) {
      setMetricType(type);
    }
  }, [selected, selectedTeam.value, ticketType, type]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'All'
          ? { value: '', label: 'All' }
          : { value: team, label: team }
      );

    metricType && setType(metricType);
    metricType && setClickedTab(metricType);

    ticket && ticket === 'bug'
      ? setTicketType([ticketTypeFilterOptions[1]])
      : ticket === 'task'
      ? setTicketType([ticketTypeFilterOptions[0]])
      : ticket === 'all'
      ? setTicketType(ticketTypeFilterOptions)
      : setTicketType([]);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  const { isOpen } = useAiAssistantStore();

  return (
    <>
      <MetaTags title="Ticket | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader
            headingText={'Ticket'}
            mb={'10px'}
            subHeadingText="Work Item represents all type of tasks and story excluding sub tasks"
            // helpDocLink={helpDocLink}
          />
          <PageToolbar
            leftComp={
              <>
                <Box mr={3}>
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                    mb={2}
                  >
                    Ticket Type
                  </Text>
                  <Select
                    placeholder="Select Ticket Type"
                    isMulti={true}
                    useBasicStyles
                    onChange={(e: any) => {
                      setTicketType(e);
                    }}
                    size="sm"
                    value={ticketType}
                    options={ticketTypeFilterOptions}
                  />
                </Box>

                <Box mr={3}>
                  <Text
                    fontSize="sm"
                    fontFamily="heading"
                    fontWeight="semibold"
                    color="text.secondary"
                    lineHeight="17px"
                    mb={2}
                  >
                    Filter By
                  </Text>
                  <Select
                    placeholder="Select"
                    useBasicStyles
                    onChange={(e: any) => {
                      setFilterBy(e);
                    }}
                    size="sm"
                    value={filterBy}
                    options={filterOptions}
                  />
                </Box>
                {filterBy.value === 'team' && (
                  <TeamMenu
                    selectedTeam={selectedTeam}
                    handleSelectedTeam={setSelectedTeam}
                    AuthActionTypes={AuthActionTypes}
                    getUser={getUser}
                    appState={appState}
                    dispatch={dispatch}
                  />
                )}

                {filterBy.value === 'project' && (
                  <Box mr={3} maxWidth="800px">
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Project
                    </Text>
                    <Select
                      placeholder="Select Projects"
                      isMulti={true}
                      useBasicStyles
                      onChange={(e: any) => {
                        setRepos(e);
                      }}
                      size="sm"
                      value={repos}
                      options={repoList?.dto?.map((repo: any) => ({
                        value: repo.name,
                        label: repo.name,
                      }))}
                    />
                  </Box>
                )}
              </>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
        </Box>

        <Grid
          my={4}
          templateColumns={
            isLargeThan2120
              ? 'repeat(6, 1fr)'
              : isLargerThan1820
              ? 'repeat(5, 1fr)'
              : 'repeat(4, 1fr)'
          }
          gap={4}
        >
          {isFetching
            ? Array(isLargeThan2120 ? 6 : isLargerThan1820 ? 5 : 4)
                .fill(null)
                .map((_, index) => (
                  <Skeleton key={index} minWidth="305px" height="109px" />
                ))
            : data?.map((item: any, idx: number) => (
                <GridItem key={idx}>
                  <MetricInfoItems
                    item={item}
                    type={type}
                    setType={setType}
                    clickedTab={clickedTab}
                    setClickedTab={setClickedTab}
                  />
                </GridItem>
              ))}
        </Grid>

        <MetricDashboard
          selected={selected}
          selectedTeam={filterBy.value === 'project' ? '' : selectedTeam.value}
          ticketType={ticketType?.length === 1 ? ticketType[0].value : 'all'}
          setType={setType}
          type={type}
          queryKey={QUERY_KEYS.allIssueThreshold}
          repos={
            filterBy.value === 'team' ? [] : repos.map((el: any) => el.value)
          }
        />
      </Box>
      {isOpen && <Chatbox />}
      {repoLoading && <Loader />}
    </>
  );
};

export default Ticket;
