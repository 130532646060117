import { Box, Grid, GridItem, Skeleton, useMediaQuery } from '@chakra-ui/react';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import {
  PageHeader,
  PageToolbar,
  TeamMenu,
  durationData,
  useQueryState,
  MetaTags,
  MetricInfoItems,
  MetricDashboard,
  useAiAssistantStore,
  removeEmptyQueryParams,
  DateFilter,
} from '@devd-client/devd/components';

import { FC, useEffect, useState } from 'react';
import { Chatbox } from '../components/Chatbox';
import { QUERY_KEYS, useDoraCards } from '../graphql';
import moment from 'moment';
import { useDashboardStore } from '@devd-client/devd/components';

export const Dora: FC = () => {
  const [isLargerThan1680] = useMediaQuery('(min-width: 1680px)');

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [metricType, setMetricType] = useQueryState('tab');
  const [appState, dispatch] = useAuth();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  const [type, setType] = useState<string>();

  const [clickedTab, setClickedTab] = useState<string>();

  const { data, isFetching } = useDoraCards(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    selectedTeam.value
  );

  useEffect(() => {
    if (!data) return;
    if (!clickedTab && !type) {
      setClickedTab(data[0]?.chartKey);
      setType(data[0]?.chartKey);
    }
  }, [data]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'All'
        ? setTeam('All')
        : setTeam(selectedTeam.value);
    }

    if (type) {
      setMetricType(type);
    }
  }, [selected, selectedTeam.value, type]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'All'
          ? { value: '', label: 'All' }
          : { value: team, label: team }
      );

    metricType && setType(metricType);
    metricType && setClickedTab(metricType);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  const { isOpen } = useAiAssistantStore();

  return (
    <>
      <MetaTags title="Dora | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        <Box>
          <PageHeader headingText={'DORA'} mb={'10px'} />
          <PageToolbar
            leftComp={
              <>
                <TeamMenu
                  selectedTeam={selectedTeam}
                  handleSelectedTeam={setSelectedTeam}
                  AuthActionTypes={AuthActionTypes}
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                />
              </>
            }
            rightComp={
              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            }
          />
        </Box>

        <Grid
          my={4}
          templateColumns={
            isLargerThan1680 ? 'repeat(4, 1fr)' : 'repeat(4, 1fr)'
          }
          gap={4}
        >
          {isFetching
            ? Array(4)
                .fill(null)
                .map((_, index) => (
                  <Skeleton key={index} minWidth="305px" height="109px" />
                ))
            : data?.map((item: any, idx: number) => (
                <GridItem key={idx}>
                  <MetricInfoItems
                    item={item}
                    type={type}
                    setType={setType}
                    clickedTab={clickedTab}
                    setClickedTab={setClickedTab}
                  />
                </GridItem>
              ))}
        </Grid>

        <MetricDashboard
          selected={selected}
          selectedTeam={selectedTeam.value}
          setType={setType}
          type={type}
          queryKey={QUERY_KEYS.allDoraThreshold}
        />
      </Box>
      {isOpen && <Chatbox />}
    </>
  );
};

export default Dora;
