import { Route, Routes, useLocation } from 'react-router';
import { useMemo } from 'react';

import {
  DetailsInitiatives,
  InitiativesProvider,
} from '@devd-client/devd/initiatives';
import { ActivityLogProvider } from '@devd-client/devd/activity-log';
import { GoalsProvider } from '@devd-client/devd/goals';
import { InvestmentsProvider } from '@devd-client/devd/investments';
import {
  ActivityLog,
  Feedback,
  Goals,
  Home,
  Initiatives,
  Investments,
  Teams,
  Reports,
  ContributorDetails,
  Survey,
  DeploymentDetails,
  SprintDetails,
  Ticket,
  Git,
  Dora,
  Sprint,
  CreateInitiatives,
  EditInitiatives,
  Quality,
  CustomDashboard,
  AddMetric,
} from './asyncPages';
import { AskReports } from '@devd-client/devd/report';
import NotFound from '../Status/404NotFound';
import { LockIcon } from '@devd-client/devd/components';
import { DetailsTeamPage } from '@devd-client/devd/team-details';
import { Card, Center, Stack, Text } from '@chakra-ui/react';

export type RouteType = {
  path: string;
  label: string;
  icon: string;
  element: JSX.Element;
};

export function Dashboard() {
  const location = useLocation();
  const routes: RouteType[] = useMemo(() => {
    return [
      {
        path: 'dashboard',
        label: 'dashboard',
        icon: 'Dashboard',
        element: <Home />,
      },
      {
        path: 'ticket',
        label: 'Ticket',
        icon: 'Ticket',
        element: <Ticket />,
      },
      {
        path: 'git',
        label: 'Git',
        icon: 'Git',
        element: <Git />,
      },
      {
        path: 'dora',
        label: 'Dora',
        icon: 'Dora',
        element: <Dora />,
      },
      {
        path: 'sprint',
        label: 'Sprint',
        icon: 'Sprint',
        element: <Sprint />,
      },
      {
        path: 'quality',
        label: 'Quality',
        icon: 'Quality',
        element: <Quality />,
      },
      {
        path: 'custom-dashboard',
        label: 'Custom Dashboard',
        icon: 'Custom',
        element: <CustomDashboard />,
      },

      {
        path: 'initiatives',
        label: 'Initiatives',
        icon: 'Initiatives',
        element: (
          <InitiativesProvider>
            <Initiatives />
          </InitiativesProvider>
        ),
      },
      {
        path: 'initiatives/:initiativesId',
        label: 'Initiatives Details',
        icon: 'Initiatives',
        element: (
          <InitiativesProvider>
            <DetailsInitiatives />
          </InitiativesProvider>
        ),
      },
      {
        path: 'initiatives/create',
        label: 'Create Initiatives',
        icon: 'Initiatives',
        element: <CreateInitiatives />,
      },
      {
        path: 'initiatives/edit',
        label: 'Edit Initiatives',
        icon: 'Initiatives',
        element: <EditInitiatives />,
      },
      {
        path: 'investments',
        label: 'Investments',
        icon: 'Initiatives',
        element: (
          <InvestmentsProvider>
            <Investments />
          </InvestmentsProvider>
        ),
      },
      {
        id: 'Feedback',
        label: 'Feedback',
        path: 'feedback',
        icon: 'Feedback',
        element: <Feedback />,
      },
      {
        id: 'Reports',
        label: 'reports',
        path: 'reports',
        icon: 'reports',
        element: <Reports />,
      },
      {
        path: 'activity-log',
        label: 'Activity log',
        icon: 'ActivityLog',
        element: (
          <ActivityLogProvider>
            <ActivityLog />
          </ActivityLogProvider>
        ),
      },
      {
        path: 'goals',
        label: 'Goals',
        icon: 'Goals',
        element: (
          <GoalsProvider>
            <Goals />
          </GoalsProvider>
        ),
      },
      {
        path: 'teams',
        label: 'Teams',
        icon: 'Teams',
        element: <Teams />,
      },
      {
        path: 'teams/:teamId',
        label: 'Team Details',
        icon: 'Teams',
        element: <DetailsTeamPage />,
      },
      {
        path: 'contributors/:contributorId',
        label: 'Contributor Details',
        icon: '',
        element: <ContributorDetails />,
      },
      {
        path: 'survey',
        label: 'Survey',
        icon: 'Survey',
        element: <Survey />,
      },
      {
        path: '*',
        label: '',
        icon: '',
        element: <NotFound />,
      },
    ];
  }, []);

  return (
    <Routes key={location.pathname}>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={`/` + route.path}
          element={
            localStorage.getItem('role') === 'MANAGER' &&
            ['investments'].includes(route.path) ? (
              <Center height="100%">
                <Card>
                  <Stack spacing={4} mb={2}>
                    <Center>
                      <LockIcon height={'80px'} width="300px" />
                    </Center>

                    <Text
                      fontFamily="heading"
                      fontSize="xl"
                      fontWeight="medium"
                      color="text.secondary"
                      alignSelf="center"
                    >
                      You don't have access.
                    </Text>
                  </Stack>
                </Card>
              </Center>
            ) : (
              route.element
            )
          }
        />
      ))}

      <Route path="/reports/ask-question" element={<AskReports />} />
      <Route path=":params/sprint/:id" element={<SprintDetails />} />
      <Route path="/sprint/:id" element={<SprintDetails />} />
      <Route path="/timeline/:id/:id" element={<DeploymentDetails />} />
      <Route path="/custom-dashboard/add-metric" element={<AddMetric />} />
      <Route path="/custom-dashboard/edit-metric" element={<AddMetric />} />
      <Route path="/teams/:teamId/add-metric" element={<AddMetric />} />
    </Routes>
  );
}

export default Dashboard;
