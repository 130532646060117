import {
  ResponsiveContainer,
  ScatterChart,
  CartesianGrid,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Legend,
  Cell,
  Label,
} from 'recharts';
import './chart.css';
import { Box, Icon } from '@chakra-ui/react';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import {
  ISSUE_AGE_CHART_KEYS,
  ISSUE_CHART_KEYS,
  PR_AGEING_CHARTS,
} from '../shared/utils';
import { useCallback, useMemo, useState } from 'react';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';
import { TriangleDownIcon } from '@chakra-ui/icons';

interface BubbleChartRechartsProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  hideBtn?: boolean;
  slant?: boolean;
  ticketType?: string;
  selected: any;
  queryKey?: string;
  repos?: string[];
}

export function BubbleChartRecharts({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  ticketType,
  selected,
  queryKey,
  repos,
}: BubbleChartRechartsProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const total = useMemo(
    () =>
      data?.reduce((a: any, b: any) => {
        return {
          value: a.value + b.value,
        };
      }),
    [data]
  );

  const renderCustomizedLabel = (props: any) => {
    const x = props.cx;
    const y = props.y - 15;
    const count = data?.[props.index].value;
    return (
      <text
        x={x}
        y={y}
        fill="#778899"
        textAnchor="middle"
        fontSize={12}
        fontWeight={500}
      >
        {chartMetadata.chartKey === 'ISSUE_PROGRESSION_RATE'
          ? `${count}%`
          : `${count} (${((count / total.value) * 100).toFixed(1)}%)`}
      </text>
    );
  };
  return (
    <>
      <Box
        position="relative"
        width="100%"
        paddingRight={5}
        height={showLabel ? '100%' : '240px'}
      >
        <Box position="absolute" right={3} bottom={6}>
          <Icon as={TriangleDownIcon} style={{ transform: 'rotate(270deg)' }} />
        </Box>

        <ResponsiveContainer>
          <ScatterChart data={data} ref={chartref}>
            <CartesianGrid
              vertical={false}
              horizontal={true}
              horizontalPoints={[135, 135, 135]}
            />

            <XAxis
              dataKey={chartMetadata.xAxis}
              type="category"
              //     padding={{ right: 150, left: 100 }}
            />
            <YAxis
              dataKey={chartMetadata.yAxis}
              type="number"
              tick={true}
              hide={true}
              tickLine={false}
              axisLine={false}
              allowDecimals={false}
              tickCount={3}
              //     label={{ value: 'Sunday', position: 'insideRight' }}
            />
            <ZAxis
              dataKey={chartMetadata.zAxis}
              name={keys?.[0]?.name ?? 'Count(%)'}
              type="number"
              scale={'auto'}
              range={[1000, 22000]}
            />
            <Tooltip
              cursor={false}
              wrapperStyle={{ outline: 'none' }}
              content={
                <CustomTooltip
                  total={total.value}
                  chartKey={chartMetadata.chartKey}
                  hideBtn={true}
                />
              }
            />

            <Scatter
              onClick={(event: any, payload: any) => {
                handleModalOpen();
                setClickedData({ ...payload, name: event.timeFrame });
                setCurrentChartMeta(chartMetadata);
              }}
              dataKey="name"
              label={renderCustomizedLabel}
            >
              {data?.map((item: any) => (
                <Cell
                  key={item.name}
                  fill={item.color}
                  style={{ outline: 'none', cursor: 'pointer' }}
                />
              ))}
            </Scatter>
          </ScatterChart>
        </ResponsiveContainer>
      </Box>
      {[
        ...ISSUE_CHART_KEYS,
        ...ISSUE_AGE_CHART_KEYS,
        ...PR_AGEING_CHARTS,
      ].includes(currentChartMeta?.chartKey) &&
        isOpen && (
          <MetricSidebar
            querykey={queryKey}
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata?.chartTitle}
            subHeading={`${moment(selected?.startDate).format(
              'DD MMM'
            )} - ${moment(selected?.endDate).format('DD MMM')}`}
            payload={{
              ...clickedData,

              payload: {
                startDate: selected?.startDate,
                endDate: selected?.endDate,
              },
            }}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              type: ticketType,
              age: clickedData?.name,
              projectsFilter: repos,
              sprintId: selected.sprintId,
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
}
