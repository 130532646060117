import { apiClient } from '@devd-client/api';

export const getCustomField = (): Promise<any> =>
  apiClient(
    `/v1/integration/org/${localStorage.getItem('orgId')}/customFields`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
