import {
  Box,
  Button,
  Img,
  Link,
  Stack,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { Navigate } from 'react-router';
import { useState } from 'react';
import { FaGithub } from 'react-icons/fa';
import { auth } from '../../helpers/auth';
import { useAuth } from '../../context/devd-auth';
import { FIREBASE_ERRORS } from '../../helpers/firebaseErrors';

import {
  EmailIcon,
  GoogleIcon,
  Loader,
  PasswordInput,
  TextInput,
  useToastHook,
} from '@devd-client/devd/components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BreakItem, BreakItemContent, FormLink } from './login.styled';
import ForgetPasswordModal from './ForgetPasswordModal';

/* eslint-disable-next-line */
export interface LoginProps {}
export enum LoginTypeEnum {
  Email = 'email',
  Google = 'google',
  Github = 'github',
}
export type LoginType = keyof typeof LoginTypeEnum;

export function Login(props: LoginProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const forgetPasswordModal = useDisclosure();
  const [newToast] = useToastHook();
  const { colors } = useTheme();

  const [loginType, setLoginType] = useState<LoginTypeEnum>(
    LoginTypeEnum.Email
  );

  const [appState] = useAuth();

  const handleLogin = (email: string, password: string) => {
    setLoginType(LoginTypeEnum.Email);
    setIsLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsLoading(false);
        setRedirect(true);
      })
      .catch((error) => {
        setIsLoading(false);
        newToast({
          message:
            FIREBASE_ERRORS[error?.message as keyof typeof FIREBASE_ERRORS] ||
            error?.message,
          status: 'error',
        });
      });
  };

  // const handleGithubLogin = () => {
  //   setLoginType(LoginTypeEnum.Github);
  //   const provider = new GithubAuthProvider();
  //   signInWithRedirect(auth, provider).catch(() => setIsLoading(false));
  // };

  const handleGoogleLogin = () => {
    setLoginType(LoginTypeEnum.Google);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => {
        setIsLoading(false);
        newToast({
          message: `Login successful`,
          status: 'success',
        });
        setRedirect(true);
      })
      .catch((err) => {
        setIsLoading(false);
        newToast({
          message: `${err.message}`,
          status: 'error',
        });
      });
  };

  return (
    <>
      {appState.user && <Navigate to="/" />}
      <Box>
        <Formik
          initialValues={{ Email: '', Password: '' }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            handleLogin(values.Email, values.Password);
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            Email: Yup.string()
              .email('Email address is invalid')
              .required('Email address is required'),
            Password: Yup.string().required('Password is required.'),
          })}
        >
          {(props) => {
            const { isSubmitting, handleSubmit } = props;
            return (
              <Stack as="form" spacing={4} onSubmit={handleSubmit as any}>
                <TextInput
                  name="Email"
                  label="Email Address"
                  placeholder="Email Address"
                  leftIcon={
                    <EmailIcon
                      height="16px"
                      width="16px"
                      color={colors.text.secondary}
                    />
                  }
                />
                <PasswordInput
                  name="Password"
                  label="Password"
                  placeholder="Password"
                />
                <Link
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="normal"
                  textAlign={'center'}
                  color="primary"
                  onClick={() => {
                    forgetPasswordModal.onOpen();
                  }}
                  alignSelf="flex-end"
                >
                  Forgot your password?
                </Link>
                <Button
                  isDisabled={isSubmitting}
                  variant="tertiary"
                  w="full"
                  fontSize="sm"
                  type="submit"
                >
                  Login
                </Button>
              </Stack>
            );
          }}
        </Formik>

        <BreakItem mt={40} mb={40}>
          <BreakItemContent>OR</BreakItemContent>
        </BreakItem>

        {/* <Button
            color="text.primary"
            isLoading={isLoading && loginType === LoginTypeEnum.Github}
            disabled={isLoading}
            spinnerPlacement="start"
            mb={5}
            variant={'secondary'}
            w="full"
            onClick={handleGithubLogin}
            leftIcon={<Icon as={FaGithub} />}
          >
            {strings.LoginWithGit}
          </Button> */}

        <Button
          isLoading={isLoading && loginType === LoginTypeEnum.Google}
          color={'text.primary'}
          isDisabled={isLoading}
          spinnerPlacement="start"
          variant={'secondary'}
          w="full"
          mb={5}
          onClick={handleGoogleLogin}
        >
          <GoogleIcon height="20px" width="20px" />
          &nbsp; Login With Google
        </Button>

        <Text
          fontFamily="heading"
          fontSize="sm"
          fontWeight="normal"
          textAlign={'center'}
          color="text.primary"
        >
          Don't have an account?
          <FormLink to={'https://devdynamics.ai/signup'}> Sign Up</FormLink>
        </Text>
      </Box>

      <Text
        align={'center'}
        fontSize={'xs'}
        fontFamily="heading"
        color="text.secondary"
        mt={'12px'}
      >
        By continuing, you agree to our{' '}
        <Link
          href={'https://devdynamics.ai/terms'}
          target="_blank"
          color="primary"
        >
          Terms{' '}
        </Link>{' '}
        of Service and{' '}
        <Link
          href={'https://devdynamics.ai/privacy'}
          target="_blank"
          color="primary"
        >
          Privacy{' '}
        </Link>{' '}
        Policy.
      </Text>

      {isLoading && <Loader />}

      {forgetPasswordModal.isOpen && (
        <ForgetPasswordModal
          isOpen={forgetPasswordModal.isOpen}
          onClose={forgetPasswordModal.onClose}
        />
      )}
    </>
  );
}
export default Login;
