import { Box, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { QUALITY_CHART_KEYS, splitTime } from '../../../shared/utils';
import {
  StyledTooltipHeading,
  StyledToopTipText,
  TooltipContainer,
  Button,
  Unit,
} from './CustomTooltip.styled';
import { useEffect } from 'react';
import { TbListDetails } from 'react-icons/tb';

const indexes = [
  'Review Responsiveness Index',
  'Review Completion',
  'Feedback Responsiveness Index',
  'Requirement Changes',
  'PR Review Health',
  'PR Merge Health',
  'Change Failure Rate',
];

const issues = [
  'Inflow',
  'Outflow',
  'Cumulative Inflow',
  'Cumulative Outflow',
  'Less than 1M',
  'Less than 15D',
  'Less than 3M',
  'Above 3M',
];

export const CustomTooltip = ({
  total,
  active,
  payload,
  unit,
  label,
  setClickedData,
  handleModalOpen,
  onClickAction,
  hideBtn = false,
  onHoverChange,
  chartKey,
  hideTooltip,
}: any) => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const tab = searchParams.get('tab');
  const breakdown = searchParams.get('breakdown');

  useEffect(() => {
    return () => onHoverChange?.(false);
  }, []);

  const isZeroValue =
    payload?.reduce(
      (acc: any, count: any) => parseFloat(count?.value) + acc,
      0
    ) === 0;

  if (active && payload && payload.length && !hideTooltip) {
    return (
      <TooltipContainer
        onMouseEnter={() => onHoverChange?.(true)}
        onMouseLeave={() => onHoverChange?.(false)}
      >
        <StyledTooltipHeading>
          {chartKey === 'PR_AGEING_DISTRIBUTION' ? ' ' : label}
        </StyledTooltipHeading>

        {payload
          .filter((item: any) => {
            return (
              item.dataKey !== 'yRollingAvg' &&
              item.dataKey !== 'timeFrame' &&
              item.dataKey !== 'YAXIS'
            );
          })
          .map((item: any, index: number) => {
            if (!total) {
              if (chartKey === 'WORK_BREAKDOWN') {
                total =
                  item.payload.helpOthers +
                  item.payload.refactor +
                  item.payload.newwork +
                  item.payload.rework;
              }
            }
            return (
              <Flex key={index} align="center" justify="space-between">
                <Flex align="center">
                  <Box
                    mr={1}
                    height="10.5px"
                    width="10.5px"
                    bg={item.color || item?.payload?.color}
                  />
                  <StyledToopTipText key={index}>
                    {item.name} -{' '}
                    {chartKey === 'WORK_BREAKDOWN'
                      ? `${item.value} (${((item.value / total) * 100).toFixed(
                          (item.value / total) * 100 < 1 ? 1 : 0
                        )}%)`
                      : item.dataKey === 'value' &&
                        chartKey === 'PR_AGEING_DISTRIBUTION'
                      ? `${item.value}(${((item.value / total) * 100).toFixed(
                          1
                        )}%)`
                      : item.dataKey === 'percentage_prs_reviewed'
                      ? `${item.value} %`
                      : item.dataKey === 'pr_pickup_time' ||
                        item.dataKey === 'pr_merge_time'
                      ? splitTime(item.value / 24)
                      : item.dataKey === 'avgValue'
                      ? splitTime(item.value / 60)
                      : item?.name === 'Hours' ||
                        item?.name === 'PR Pickup Time' ||
                        item?.name === 'PR Merge Time' ||
                        item?.name === 'Coding Time' ||
                        item?.name === 'Deploy Time' ||
                        item?.name === 'PR Cycle Time' ||
                        item?.name === 'Pickup Time' ||
                        item?.name === 'Merge Time' ||
                        unit === 'Hour'
                      ? splitTime(item.value)
                      : item?.name === 'Cycle Time'
                      ? `${item.value} Days`
                      : indexes.includes(item?.payload?.name) ||
                        indexes.includes(item?.name)
                      ? `${item.value} %`
                      : issues.includes(item?.name)
                      ? `${item.value} issues`
                      : [
                          'High',
                          'Low',
                          'Lowest',
                          'Medium',
                          'Critical',
                          'Highest',
                        ].includes(item?.name) &&
                        tab === 'MEAN_TIME_TO_RECOVERY_DISTRIBUTION'
                      ? splitTime(item.value)
                      : tab === 'ISSUE_PROGRESSION_RATE'
                      ? `${item.value}%`
                      : item.value}{' '}
                    {breakdown && (
                      <Unit>
                        {unit === 'Count' ? '' : unit === 'Hour' ? '' : unit}
                      </Unit>
                    )}
                  </StyledToopTipText>
                </Flex>
                {(QUALITY_CHART_KEYS.includes(tab as string) ||
                  QUALITY_CHART_KEYS.includes(chartKey)) && (
                  <Box ml={1}>
                    <Tooltip hasArrow label={'View Data'}>
                      <IconButton
                        aria-label="View Data"
                        color="text.primary"
                        icon={<TbListDetails size={18} />}
                        background="transparent"
                        size="xs"
                        style={{ pointerEvents: 'all' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleModalOpen();
                          setClickedData(item);
                          onClickAction();
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Flex>
            );
          })}

        {!hideBtn && payload[0].name === 'Met' && (
          <Button
            style={{ pointerEvents: 'all' }}
            onClick={() => {
              handleModalOpen();
              setClickedData(
                payload?.map((item: any) => ({ ...item, title: 'Not Met' }))
              );
              onClickAction();
            }}
          >
            View Exception
          </Button>
        )}

        {!QUALITY_CHART_KEYS.includes((tab as string) || chartKey) && (
          <>
            {!hideBtn &&
              localStorage.getItem('role') !== 'EMPLOYEE' &&
              payload[0].name !== 'Met' &&
              payload?.some((item: any) => item.value !== 0) &&
              !isZeroValue && (
                <Button
                  style={{ pointerEvents: 'all' }}
                  onClick={() => {
                    handleModalOpen();
                    setClickedData(payload);
                    onClickAction();
                  }}
                >
                  View Data
                </Button>
              )}
          </>
        )}
      </TooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
