import { useQuery } from '@tanstack/react-query';
import {
  averageActiveDaysDetailGraphql,
  changeFailureGraphql,
  commitGraphql,
  customMetricsGraphql,
  DeploymentDetails,
  getContributorGraphql,
  GoalMetricDetailsGit,
  GoalMetricDetailsJira,
  issueAgeingDetailsGraphql,
  issueChartsGraphql,
  memberCountGraphql,
  PrAgeingDetailsGraphql,
  prChartsGraphql,
  prHealthIndexGraphql,
  prReviewResponsivenessDetailsGraphql,
  QualityDetails,
  VulnerabilityAlertsDetails,
  requirementStabilityDetailsGraphql,
  WellbeingDetails,
  workingAgreementGraphql,
  locVsPrCycleTimeDetailsGraphql,
  commitReportDetailWithBreakdownGraphql,
  customBuildMetricGraphql,
  customIssueMetricGraphql,
} from './query';
import {
  CFR_CHART_KEYS,
  CONTRIBUTOR_CHART_KEYS,
  DEPLOY_CHART_KEYS,
  GOAL_METRIC_GIT_KEYS,
  GOAL_METRIC_JIRA_KEYS,
  ISSUE_CHART_KEYS,
  PR_CHART_KEYS,
  WELLBEING_CHART_KEYS,
  WORKING_AGREEMENT_KEYS,
  MEMBER_CHART_KEYS,
  COMMIT_CHART_KEYS,
  QUALITY_CHART_KEYS,
  ISSUE_AGE_CHART_KEYS,
  REQUIREMENT_STABILITY_CHARTS,
  PR_AGEING_CHARTS,
  REVIEW_RESPONSIVENESS_INDEX_CHARTS,
  PR_HEALTH_INDEX_CHARTS,
  ACTIVE_DAYS_CHARTS,
  DEPENDENCY_VULNERABILITIES_CHART_KEYS,
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  WORK_BREAKDOWN_CHARTS,
  ISSUE_LIFECYCLE_DIST_CHARTS,
} from '../../shared/utils';

export const allMetricsList: { type: string; query: string }[] = [
  ...PR_CHART_KEYS.map((key: string) => ({ type: key, query: 'prReport' })),

  ...GOAL_METRIC_GIT_KEYS.map((key: string) => ({
    type: key,
    query: 'goalMetricDetailsGit',
  })),

  ...GOAL_METRIC_JIRA_KEYS.map((key: string) => ({
    type: key,
    query: 'goalMetricDetailsJira',
  })),

  ...ISSUE_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'issueReport',
  })),

  ...CFR_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'changeFailure',
  })),

  ...DEPLOY_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'deploymentDetails',
  })),

  ...WELLBEING_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'wellbeing',
  })),

  ...CONTRIBUTOR_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'contributors',
  })),

  ...MEMBER_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'memberCount',
  })),

  ...COMMIT_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'commitReport',
  })),

  ...WORKING_AGREEMENT_KEYS.map((key: string) => ({
    type: key,
    query: 'workingAgreement',
  })),

  ...QUALITY_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'qualityDetail',
  })),

  ...DEPENDENCY_VULNERABILITIES_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'vulnerabilityAlertsDetail',
  })),

  ...ISSUE_AGE_CHART_KEYS.map((key: string) => ({
    type: key,
    query: 'issueAgeing',
  })),

  ...REQUIREMENT_STABILITY_CHARTS.map((key: string) => ({
    type: key,
    query: 'requirementStability',
  })),

  ...PR_AGEING_CHARTS.map((key: string) => ({
    type: key,
    query: 'prAgeingDistribution',
  })),

  ...REVIEW_RESPONSIVENESS_INDEX_CHARTS.map((key: string) => ({
    type: key,
    query: 'prReviewResponsivenessDetails',
  })),

  ...PR_HEALTH_INDEX_CHARTS.map((key: string) => ({
    type: key,
    query: 'prHealthIndexDetails',
  })),

  ...LOC_VS_PR_CYCLE_TIME_CHARTS.map((key: string) => ({
    type: key,
    query: 'locVsPrCycleTimeDetails',
  })),

  ...ACTIVE_DAYS_CHARTS.map((key: string) => ({
    type: key,
    query: 'activeDays',
  })),

  ...WORK_BREAKDOWN_CHARTS.map((key: string) => ({
    type: key,
    query: 'workBreakdown',
  })),

  ...ISSUE_LIFECYCLE_DIST_CHARTS.map((key: string) => ({
    type: key,
    query: 'issueReport',
  })),
];

type DynamicQueryKey = string;

enum MetricsType {
  PrReport = 'prReport',
  IssueReport = 'issueReport',
  DeploymentDetails = 'deploymentDetails',
  ChangeFailure = 'changeFailure',
  GoalMetricDetailsGit = 'goalMetricDetailsGit',
  GoalMetricDetailsJira = 'goalMetricDetailsJira',
  Wellbeing = 'wellbeing',
  Contributors = 'contributors',
  CommitReport = 'commitReport',
  WorkingAgreement = 'workingAgreement',
  MemberCount = 'memberCount',
  QualityDetail = 'qualityDetail',
  VulnerabilityAlertsDetail = 'vulnerabilityAlertsDetail',
  IssueAgeing = 'issueAgeing',
  RequirementStability = 'requirementStability',
  PrAgeingDistribution = 'prAgeingDistribution',
  PrReviewResponsivenessDetails = 'prReviewResponsivenessDetails',
  PrHealthIndexDetails = 'prHealthIndexDetails',
  LocVsPrCycleTimeDetails = 'locVsPrCycleTimeDetails',
  ActiveDays = 'activeDays',
  WorkBreakdown = 'workBreakdown',
}

export const useChartTableGql = (
  startDate: string,
  endDate: string,
  metricType: string,
  filter: { [key: string]: string | string[] },
  pageNumber: number,
  pageSize: number,
  detailEntity: string
) => {
  const query = allMetricsList
    .filter((item: any) => item.type === metricType)
    .map((el: any) => el.query)[0];

  let queryFn = () =>
    prChartsGraphql(
      startDate,
      endDate,
      metricType,
      filter,
      pageNumber,
      pageSize
    );

  switch (query) {
    case MetricsType.PrReport:
      queryFn = () =>
        prChartsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.IssueReport:
      queryFn = () =>
        issueChartsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.ChangeFailure:
      queryFn = () =>
        changeFailureGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.DeploymentDetails:
      queryFn = () =>
        DeploymentDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;
    case MetricsType.Wellbeing:
      queryFn = () =>
        WellbeingDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.QualityDetail:
      queryFn = () =>
        QualityDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.VulnerabilityAlertsDetail:
      queryFn = () =>
        VulnerabilityAlertsDetails(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.GoalMetricDetailsGit:
      queryFn = () =>
        GoalMetricDetailsGit(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;
    case MetricsType.GoalMetricDetailsJira:
      queryFn = () =>
        GoalMetricDetailsJira(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;
    case MetricsType.Contributors:
      queryFn = () => getContributorGraphql(startDate, endDate, filter);
      break;

    case MetricsType.WorkingAgreement:
      queryFn = () => workingAgreementGraphql(startDate, endDate, filter);
      break;

    case MetricsType.MemberCount:
      queryFn = () => memberCountGraphql(filter);
      break;

    case MetricsType.CommitReport:
      queryFn = () =>
        commitGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.IssueAgeing:
      queryFn = () =>
        issueAgeingDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.RequirementStability:
      queryFn = () =>
        requirementStabilityDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.PrAgeingDistribution:
      queryFn = () => PrAgeingDetailsGraphql(startDate, endDate, filter);
      break;

    case MetricsType.PrReviewResponsivenessDetails:
      queryFn = () =>
        prReviewResponsivenessDetailsGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.PrHealthIndexDetails:
      queryFn = () =>
        prHealthIndexGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.LocVsPrCycleTimeDetails:
      queryFn = () =>
        locVsPrCycleTimeDetailsGraphql(startDate, endDate, filter);
      break;

    case MetricsType.ActiveDays:
      queryFn = () =>
        averageActiveDaysDetailGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    case MetricsType.WorkBreakdown:
      queryFn = () =>
        commitReportDetailWithBreakdownGraphql(
          startDate,
          endDate,
          metricType,
          filter,
          pageNumber,
          pageSize
        );
      break;

    default:
      switch (detailEntity) {
        case 'BuildDetailCustom': {
          queryFn = () =>
            customBuildMetricGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageNumber,
              pageSize
            );
          break;
        }
        case 'IssueDetailCustom': {
          queryFn = () =>
            customIssueMetricGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageNumber,
              pageSize
            );
          break;
        }
        default: {
          queryFn = () =>
            customMetricsGraphql(
              startDate,
              endDate,
              metricType,
              filter,
              pageNumber,
              pageSize
            );
          break;
        }
      }
      break;
  }

  return useQuery(
    [
      metricType as DynamicQueryKey,
      startDate,
      endDate,
      filter,
      pageNumber,
      pageSize,
    ],
    queryFn,
    {
      select: (data) => getPayload(query, data),
      enabled: !!metricType,
      keepPreviousData: true,
    }
  );
};

const getPayload = (query: string, data: any) => {
  switch (query) {
    case MetricsType.PrReport:
      return {
        ...data?.PrReportDetail,
      };
    case MetricsType.IssueReport:
      return {
        ...data?.IssueReportDetail,
      };
    case MetricsType.ChangeFailure:
      return {
        ...data?.ChangeFailureDetail,
      };
    case MetricsType.DeploymentDetails:
      return {
        ...data?.DeploymentDetails,
      };
    case MetricsType.Wellbeing:
      return {
        ...data?.WellBeingDetailsByType,
      };
    case MetricsType.GoalMetricDetailsGit:
      return {
        ...data?.GoalMetricDetailsGit,
      };
    case MetricsType.GoalMetricDetailsJira:
      return {
        ...data?.GoalMetricDetailsJira,
      };
    case MetricsType.Contributors:
      return {
        ...data?.ContributorDetails,
      };
    case MetricsType.MemberCount:
      return {
        ...data?.TeamMembers,
      };
    case MetricsType.WorkingAgreement:
      return {
        ...data?.TeamStatsWorkingAgreementDetails,
      };
    case MetricsType.QualityDetail:
      return {
        ...data?.QualityBranchDetailByType,
      };

    case MetricsType.VulnerabilityAlertsDetail:
      return {
        ...data?.VulnerabilityAlertsDetail,
      };
    case MetricsType.CommitReport:
      return {
        ...data?.CommitReportDetail,
        data: data?.CommitReportDetail.data.map((item: any) => ({
          ...item,
          id: item.attributes.find((attr: any) => attr?.key === 'Related PR Id')
            ?.value,
        })),
      };
    case MetricsType.IssueAgeing:
      return {
        ...data?.IssueDetailsForAge,
      };
    case MetricsType.RequirementStability:
      return {
        ...data?.RequirementStabilityDetails,
      };

    case MetricsType.PrAgeingDistribution:
      return {
        ...data?.PrAgeingDetails,
      };

    case MetricsType.PrReviewResponsivenessDetails:
      return {
        ...data?.PrReviewResponsivenessDetails,
      };

    case MetricsType.PrHealthIndexDetails:
      return {
        ...data?.PrHealthIndexesDetails,
      };

    case MetricsType.LocVsPrCycleTimeDetails:
      return {
        ...data?.LocVsPrCycleTimeDetails,
      };

    case MetricsType.ActiveDays:
      return (
        data &&
        data?.AverageActiveDaysDetail && [...data.AverageActiveDaysDetail]
      );

    case MetricsType.WorkBreakdown:
      return {
        ...data?.CommitReportDetailWithBreakdown,
        data: data?.CommitReportDetailWithBreakdown?.data?.map((item: any) => {
          const total =
            item.helped + item.newwork + item.refactor + item.rework;

          return {
            ...item,
            readings: [
              {
                name: 'helped',
                value: parseFloat(((item.helped / total) * 100)?.toFixed(4)),
                color: '#F1B813',
              },
              {
                name: 'newwork',
                value: parseFloat(((item.newwork / total) * 100)?.toFixed(4)),
                color: '#71E03A',
              },
              {
                name: 'refactor',
                value: parseFloat(((item.refactor / total) * 100)?.toFixed(4)),
                color: '#0095E6',
              },
              {
                name: 'rework',
                value: parseFloat(((item.rework / total) * 100)?.toFixed(4)),
                color: '#FF5230',
              },
            ],
          };
        }),
      };

    default:
      return data?.CustomMetricDetailJira
        ? {
            ...data?.CustomMetricDetailJira,
          }
        : data?.BuildDetailCustom
        ? {
            ...data?.BuildDetailCustom,
          }
        : data?.IssueDetailCustom
        ? {
            ...data?.IssueDetailCustom,
          }
        : {};
  }
};
