import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useTheme,
} from '@chakra-ui/react';
import {
  Breadcrumbs,
  CustomAlert,
  DateFilter,
  ISelect,
  Loader,
  MetricDetails,
  PeriodType,
  TeamMenu,
  durationData,
  removeEmptyQueryParams,
  useDashboardStore,
  useFavTeam,
  useQueryState,
  useRemoveFavTeam,
  useToastHook,
} from '@devd-client/devd/components';
import RightContent from '../components/RightContent';
import { useTeamDetailsStore } from '../store/teamDetail';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { TeamDetailsTabOptions } from '../helpers/team-details.types';
import { useFetchTeamPage, useTeamDetails } from '../api';
import { FaUserTie } from 'react-icons/fa';
import { ImTree } from 'react-icons/im';
import { RiGroupLine } from 'react-icons/ri';
import { MdOutlineMarkEmailUnread } from 'react-icons/md';
import { CgListTree } from 'react-icons/cg';
import { FiClock } from 'react-icons/fi';
import TeamInfoItem from '../components/TeamInfoItem';
import { useSearchParams } from 'react-router-dom';
import { IoSettingsOutline } from 'react-icons/io5';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import { BiHelpCircle } from 'react-icons/bi';
import moment from 'moment';

export const DetailsTeamPage = () => {
  const { colors } = useTheme();
  const [appState, dispatch] = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const metricType = searchParams.get('type');
  const [newToast] = useToastHook();

  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [tab, setTab] = useQueryState('tab');

  const [selectedTab, setSelectedTab] = useState<any>('teamStats');

  const { setMemberCount } = useTeamDetailsStore();

  const [periodOptions, setPeriodOptions] = useState<any>(durationData);

  const { mutate: handleFavTeam, isLoading: handleFavTeamLoading } =
    useFavTeam();
  const { mutate: removeFavTeam, isLoading: removeFavTeamLoading } =
    useRemoveFavTeam();

  const [getUserLoading, setGetUserLoading] = useState(false);
  const { selected, selectedTeam, setSelected, setSelectedTeam } =
    useDashboardStore();

  const { data: teamPageData } = useFetchTeamPage();
  const helpDocLink = teamPageData?.dto?.helpDoc;

  const queryString = window.location.search;

  const { data: teamData, isFetching: teamDetailsLoading } = useTeamDetails(
    decodeURIComponent(pathname.split('/')[2]),
    setMemberCount
  );

  useEffect(() => {
    if (typeof selectedTab !== 'undefined') {
      setTab(selectedTab);
    }

    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }
  }, [selected, selectedTab]);

  useEffect(() => {
    tab && setSelectedTab(tab as TeamDetailsTabOptions);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  const handleHelpIconClick = () => {
    window.open(
      helpDocLink,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=270,left=800,width=700,height=600'
    );
  };

  return (
    <>
      {!metricType ? (
        <Flex direction="column" width="100%" minH="100%">
          <Breadcrumbs />
          <Flex mt={2} align="center" w="full" justify="space-between">
            <Flex direction="column">
              <Flex align="center">
                <Text fontFamily="heading" fontSize="lg" fontWeight="semibold">
                  {decodeURIComponent(pathname.split('/')[2])}
                </Text>
                {helpDocLink && (
                  <Box
                    as={BiHelpCircle}
                    onClick={handleHelpIconClick}
                    _hover={{ cursor: 'pointer', color: 'primary' }}
                    transition="color 0.3s"
                    lineHeight={'28px'}
                    fontSize={'xl'}
                    ml={'2px'}
                  />
                )}
              </Flex>
              {teamPageData?.dto?.title && (
                <Text
                  fontFamily="heading"
                  fontSize="sm"
                  fontWeight="normal"
                  color="text.secondary"
                >
                  {teamPageData?.dto?.title}
                </Text>
              )}
            </Flex>

            <HStack spacing={2}>
              <TeamMenu
                selectedTeam={selectedTeam}
                handleSelectedTeam={setSelectedTeam}
                AuthActionTypes={AuthActionTypes}
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                noDefault
                hideShowDetails
                actionHandler={(selected: string) => {
                  const updatedURL = `${pathname.replace(
                    /\/teams\/[^/]+/,
                    `/teams/${selected}`
                  )}${queryString}`;

                  navigate(updatedURL);
                }}
                mb={2}
              />

              <DateFilter
                getUser={getUser}
                appState={appState}
                dispatch={dispatch}
                AuthActionTypes={AuthActionTypes}
              />
            </HStack>
          </Flex>

          {/* <Text fontFamily={'heading'} color="text.secondary">
            {teamData?.dto?.description}
          </Text> */}
          <Flex align="flex-end" justify="space-between">
            <HStack mt={6}>
              <TeamInfoItem
                icon={RiGroupLine}
                label={'Click to see details.'}
                clickableTitle
                onTitleClick={() =>
                  navigate(
                    `/settings/teams/${decodeURIComponent(
                      pathname.split('/')[2]
                    )}/member`
                  )
                }
                title="Member"
                value={teamData?.dto?.memberCount || '0'}
              />

              <Box height="100%" width="1.5px" bg="gray.300" />

              <TeamInfoItem
                icon={FaUserTie}
                label={
                  <>
                    {teamData?.dto?.managers?.length === 0 ? (
                      <Text>0 Manager</Text>
                    ) : (
                      <Stack>
                        {teamData?.dto?.managers?.map((manager: string) => (
                          <Text key={manager}>{manager}</Text>
                        ))}
                      </Stack>
                    )}
                  </>
                }
                title="Manager"
                value={teamData?.dto?.managers?.length || '0'}
              />

              <Box height="100%" width="1.5px" bg="gray.300" />

              <TeamInfoItem
                icon={ImTree}
                label={
                  <>
                    {teamData?.dto?.subTeams?.length === 0 ? (
                      <Text>0 Subteam</Text>
                    ) : (
                      <Stack>
                        {teamData?.dto?.subTeams?.map(
                          (team: { name: string }) => (
                            <Text key={team.name}>{team.name}</Text>
                          )
                        )}
                      </Stack>
                    )}
                  </>
                }
                title="Subteam"
                value={teamData?.dto?.subTeams?.length || '0'}
              />

              <Box height="100%" width="1.5px" bg="gray.300" />

              <TeamInfoItem
                icon={MdOutlineMarkEmailUnread}
                title="Daily Digest"
                value={teamData?.dto?.dailyDigest ? 'ON' : 'OFF'}
              />

              <Box height="100%" width="1.5px" bg="gray.300" />
              <TeamInfoItem
                icon={FiClock}
                title="Working Hours"
                value={teamData?.dto?.workingHours}
              />

              <Box height="100%" width="1.5px" bg="gray.300" />
              <TeamInfoItem
                icon={CgListTree}
                title="Parent Team"
                value={teamData?.dto?.parentTeam || 'None'}
              />
            </HStack>

            <HStack>
              <Tooltip hasArrow label="Edit Team">
                <IconButton
                  bg="transparent"
                  border="1px solid"
                  borderColor="#ccc"
                  size="sm"
                  onClick={() =>
                    navigate(
                      `/settings/teams/${decodeURIComponent(
                        pathname.split('/')[2]
                      )}`
                    )
                  }
                  aria-label="Search database"
                  icon={
                    <IoSettingsOutline
                      size={24}
                      color={colors.text.secondary}
                    />
                  }
                />
              </Tooltip>

              <Tooltip
                hasArrow
                placement="bottom-end"
                label={
                  decodeURIComponent(pathname.split('/')[2]) ===
                  appState?.user?.dto?.team
                    ? 'Remove from Favourite Team'
                    : 'Mark as Favourite Team'
                }
              >
                <IconButton
                  bg="transparent"
                  size="sm"
                  border="1px solid"
                  borderColor="#ccc"
                  aria-label="Favorite"
                  icon={
                    decodeURIComponent(pathname.split('/')[2]) ===
                    appState?.user?.dto?.team ? (
                      <AiFillHeart size={24} color={colors.text.secondary} />
                    ) : (
                      <AiOutlineHeart size={24} color={colors.text.secondary} />
                    )
                  }
                  onClick={() => {
                    decodeURIComponent(pathname.split('/')[2]) ===
                    appState?.user?.dto?.team
                      ? removeFavTeam(
                          {
                            name: decodeURIComponent(pathname.split('/')[2]),
                          },
                          {
                            onSuccess: () => {
                              setGetUserLoading(true);
                              getUser()
                                .then((res: any) => {
                                  setGetUserLoading(false);
                                  dispatch({
                                    type: AuthActionTypes.AUTH_READY,
                                    payload: { ...appState.user, ...res },
                                  });
                                })
                                .catch((err) => {
                                  newToast({
                                    message: 'Something went wrong',
                                    status: 'error',
                                  });
                                  setGetUserLoading(false);
                                });
                            },
                          }
                        )
                      : appState?.user?.dto?.team
                      ? newToast({
                          message: `${appState?.user?.dto?.team} is marked as Favourite. Remove it from favourite and try again.`,
                          status: 'error',
                        })
                      : handleFavTeam(
                          {
                            name: decodeURIComponent(pathname.split('/')[2]),
                          },
                          {
                            onSuccess: () => {
                              setGetUserLoading(true);
                              getUser()
                                .then((res: any) => {
                                  setGetUserLoading(false);
                                  dispatch({
                                    type: AuthActionTypes.AUTH_READY,
                                    payload: { ...appState.user, ...res },
                                  });
                                })
                                .catch((err) => {
                                  newToast({
                                    message: 'Something went wrong',
                                    status: 'error',
                                  });
                                  setGetUserLoading(false);
                                });
                            },
                          }
                        );
                  }}
                />
              </Tooltip>
            </HStack>
          </Flex>

          {(teamData?.dto?.memberCount === 0 ||
            teamData?.dto?.memberCount === null) && (
            <Box>
              <CustomAlert
                content={
                  <Text fontFamily="heading" fontSize="xs" color="text.primary">
                    {`${decodeURIComponent(
                      pathname.split('/')[2]
                    )} don’t have any members. Please add a `}
                    <Text
                      as="span"
                      textDecor="underline"
                      cursor="pointer"
                      onClick={() =>
                        navigate(
                          `/settings/teams/${decodeURIComponent(
                            pathname.split('/')[2]
                          )}/member`
                        )
                      }
                    >
                      member
                    </Text>{' '}
                    to see data.
                  </Text>
                }
                status={'error'}
              />
            </Box>
          )}

          <Divider my={2} />

          <Stack>
            <RightContent
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              selected={selected}
              selectedTeam={selectedTeam}
            />
          </Stack>
        </Flex>
      ) : (
        <MetricDetails
          initialTeam={decodeURIComponent(pathname.split('/')[2])}
        />
      )}
      {(teamDetailsLoading ||
        handleFavTeamLoading ||
        removeFavTeamLoading ||
        getUserLoading) && <Loader />}
    </>
  );
};

export default DetailsTeamPage;
