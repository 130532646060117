import { Box } from '@chakra-ui/react';
import { DataGrid, PeriodType } from '@devd-client/devd/components';
import { Paginate } from '@devd-client/devd/components';
import { createColumnHelper } from '@tanstack/react-table';
import { useFetchSprintVelocityGql } from '../../../graphql';
import { useCallback, useMemo, useState } from 'react';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';

const StyledLink = styled.div`
  text-decoration: underline;
  color: #0095e6;
  cursor: pointer;
`;

interface SprintVelocityProps {
  selected: PeriodType;
}

const SprintVelocity = ({ selected }: SprintVelocityProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const team = decodeURIComponent(pathname.split('/')[2]);

  const { data: sprintVelocityData, isLoading } = useFetchSprintVelocityGql(
    selected.startDate,
    selected.endDate,
    selected?.sprintId,
    decodeURIComponent(pathname.split('/')[2]),
    currentPage,
    20
  );

  const handlePageClick = useCallback(
    (page: number) => {
      setCurrentPage(page - 1);
    },
    [currentPage]
  );

  const rows: any[] = useMemo(
    () => sprintVelocityData?.data || [],
    [sprintVelocityData?.data]
  );

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('sprintName', {
        cell: (info) => (
          <StyledLink
            onClick={() =>
              navigate(
                `/teams/sprint/${info?.row?.original?.sprintId}${
                  team && `?team=${team}`
                }`
              )
            }
          >
            {info?.getValue()}
          </StyledLink>
        ),
        header: 'Sprint Name',
      }),
      columnHelper.accessor('sprintId', {
        cell: (info) => info.getValue(),
        header: 'Sprint Id',
        size: 80,
      }),
      columnHelper.accessor('startDate', {
        cell: (info) => info.getValue(),
        header: 'Start Date',
        size: 100,
      }),
      columnHelper.accessor('endDate', {
        cell: (info) => info.getValue(),
        header: 'End Date',
        size: 100,
      }),
      columnHelper.accessor('commitedTasks', {
        cell: (info) => info.getValue(),
        header: 'Commited Tasks',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('completedTasks', {
        cell: (info) => info.getValue(),
        header: 'Completed Tasks',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('unplannedTasks', {
        cell: (info) => info.getValue(),
        header: 'Unplanned Tasks',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('commitedSP', {
        cell: (info) => info.getValue(),
        header: 'Commited SP',
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('completedSP', {
        cell: (info) => info.getValue(),
        header: 'Completed SP',
        size: 100,
        meta: {
          isNumeric: true,
        },
      }),
    ],
    [sprintVelocityData]
  );

  return (
    <Box>
      <DataGrid
        showLoader={isLoading}
        columns={columns}
        data={rows}
        maxH="24rem"
        sticky="firstCol"
      />
      <Box marginLeft="-20px">
        <Paginate
          currentPage={sprintVelocityData?.currentPage + 1}
          totalPageCount={sprintVelocityData?.totalPages}
          pageSize={10}
          onPageChange={(page) => handlePageClick(page as number)}
        />
      </Box>
    </Box>
  );
};

export default SprintVelocity;
