import { Box } from '@chakra-ui/react';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  font-family: 'Manrope';
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  border: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const CustomTooltip = ({ payload }: any) => {
  return (
    <TooltipContainer>
      <Box fontWeight="600" fontSize="12px" color="#999999">
        {payload[0]?.payload?.dist} {payload[0]?.name} :
      </Box>
      <Box fontWeight="600" fontSize="12px" ml="5px">
        {payload[0]?.value}
      </Box>
    </TooltipContainer>
  );
};

interface PieChartSharedProps {
  data: any;
  legendWidth?: number;
  pieSize?: number;
}

export const PieChartShared: React.FC<PieChartSharedProps> = ({
  data,
  legendWidth,
  pieSize,
}) => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const count = data[index].value;

    return (
      <text
        x={x}
        y={y}
        fill="#778899"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={12}
        fontWeight={500}
      >
        {`${count}`}
      </text>
    );
  };

  return (
    <ResponsiveContainer height="100%" width="100%">
      <PieChart
        margin={{
          left: 20,
          bottom: 20,
        }}
      >
        <Pie
          data={data}
          cx={'40%'}
          cy={'50%'}
          outerRadius={pieSize || 50}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          labelLine={false}
          label={renderCustomizedLabel}
          style={{ outline: 'none', marginBottom: 10 }}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
          wrapperStyle={{ outline: 'none' }}
        />

        <Legend
          layout="horizontal"
          iconType="circle"
          iconSize={10}
          wrapperStyle={{
            fontSize: 10.5,
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
