/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useState } from 'react';
import Loader from '../../appLoader';
import { useToastHook } from '../../appToast';
import { useIntegrationMutation } from '../api';
import {
  InputFieldType,
  ModalFormFields,
} from '../helpers/integrationDetails.types';
import { goToLink } from '../helpers/integrationDetails.utils';
import {
  BottomSection,
  ConnectText,
  Form,
  IconContainer,
  InfoBox,
  MiddleSection,
  Textline,
  TopSection,
} from './IntegrationModal.styled';
import { BiHelpCircle, BiInfoCircle } from 'react-icons/bi';

export const IntegrationModal = ({
  handleIntegrateModalClose,
  isOpen,
  intDetailsData,
  handleConfirmModalOpen,
}: any) => {
  const initialValues: ModalFormFields = {
    url: '',
    token: '',
    workspaces: '',
    password: '',
    username: '',
  };
  const [completeIntegration, setCompleteIntegration] =
    useState<boolean>(false);

  const { mutate: handleIntegration } = useIntegrationMutation();

  const [newToast] = useToastHook();
  const [loading, isLoading] = useState(false);

  const handleHelpIconClick = () => {
    window.open(
      intDetailsData?.dto?.links?.helpDoc,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=270,left=800,width=700,height=600'
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        handleIntegrateModalClose();
      }}
      size="xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        margin={0}
        rounded="lg"
        minH={completeIntegration ? '240px' : '400px'}
      >
        <ModalBody
          display="flex"
          flexDirection="column"
          justifyContent={'space-between'}
          padding="30px"
        >
          <>
            {completeIntegration ? (
              <Box
                w="100%"
                h="240px"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  You are redirected to {intDetailsData?.dto?.name} website.
                  Please complete the integration.
                </Box>
                <Button
                  width="100%"
                  color="#fff"
                  background="text.primary"
                  fontSize="lg"
                  fontWeight="normal"
                  fontFamily="heading"
                  borderRadius="lg"
                  lineHeight="130%"
                  onClick={() => {
                    handleIntegrateModalClose();
                  }}
                >
                  {`Close`}
                </Button>
              </Box>
            ) : (
              <>
                <TopSection>
                  <InfoBox>
                    <IconContainer>
                      <Image src={intDetailsData?.dto?.links?.icon} />
                    </IconContainer>
                    <Flex alignItems={'center'} mt={'20px'}>
                      {intDetailsData?.dto?.name && (
                        <ConnectText>{`Connect to ${intDetailsData?.dto?.name}`}</ConnectText>
                      )}

                      {intDetailsData?.dto?.links?.helpDoc && (
                        <Box
                          as={BiHelpCircle}
                          onClick={handleHelpIconClick}
                          _hover={{ cursor: 'pointer', color: 'primary' }}
                          transition="color 0.3s"
                          lineHeight={'24px'}
                          fontSize={'xl'}
                          ml={'2px'}
                        />
                      )}
                    </Flex>
                  </InfoBox>
                  {(intDetailsData?.dto?.name === 'GitHub' ||
                    intDetailsData?.dto?.name === 'Bitbucket' ||
                    intDetailsData?.dto?.name === 'GitLab') && (
                    <Box
                      rounded="md"
                      p={2}
                      mt={1}
                      border="0.5px"
                      bgColor={'#eff5fe'}
                      alignContent={'center'}
                    >
                      <Flex alignItems={'center'}>
                        <BiInfoCircle
                          size={18}
                          color={'#3981f7'}
                          fontWeight={'bold'}
                        />
                        <Text color={'#3981f7'} ml={2} fontWeight={'bold'}>
                          Security: Your code is safe. We do not fetch, copy or
                          transfer.
                        </Text>
                      </Flex>
                      <Text ml="25px" fontSize={'sm'} fontFamily="heading">
                        DevDynamics doesn't access your code; it only uses the
                        metadata of pull requests, commits, issues, and
                        deployments. You can disconnect projects and
                        repositories anytime.
                      </Text>
                    </Box>
                  )}
                  {intDetailsData?.dto?.info && (
                    <Textline>{intDetailsData?.dto?.info}</Textline>
                  )}
                </TopSection>

                {intDetailsData?.dto?.fields &&
                  intDetailsData?.dto?.fields?.length >= 1 && (
                    <MiddleSection>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={(values) => {
                          isLoading(true);
                          const properties = [];

                          if (values.url !== '')
                            properties.push({ name: 'url', value: values.url });

                          if (values.token !== '')
                            properties.push({
                              name: 'token',
                              value: values.token,
                            });

                          if (values.workspaces !== '')
                            properties.push({
                              name: 'workspaces',
                              value: values.workspaces,
                            });

                          if (values.password !== '') {
                            properties.push({
                              name: 'password',
                              value: values.password,
                            });
                          }

                          if (values.username !== '') {
                            properties.push({
                              name: 'username',
                              value: values.username,
                            });
                          }

                          handleIntegration(
                            {
                              name: intDetailsData?.dto?.name,
                              type: intDetailsData?.dto?.type,
                              active: true,
                              properties,
                            },
                            {
                              onSuccess: () => {
                                isLoading(false);
                                if (intDetailsData?.dto?.links?.oauth_url) {
                                  goToLink(
                                    intDetailsData?.dto?.links?.oauth_url
                                  );
                                  setCompleteIntegration((prev) => !prev);
                                } else {
                                  handleIntegrateModalClose();
                                  setTimeout(() => {
                                    handleConfirmModalOpen();
                                  }, 2000);
                                }
                              },
                              onError: (err: any) => {
                                isLoading(false);
                                newToast({
                                  status: 'error',
                                  message: err.message,
                                });
                              },
                            }
                          );
                        }}
                      >
                        {({ handleSubmit, errors, touched }: any) => (
                          <Form onSubmit={handleSubmit}>
                            {intDetailsData?.dto?.fields &&
                              intDetailsData?.dto?.fields.map((item: any) => (
                                <>
                                  {item.key === InputFieldType.url ? (
                                    <FormControl
                                      mb="10px"
                                      isInvalid={!!errors.url && touched.url}
                                    >
                                      <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="text.secondary"
                                        lineHeight="24px"
                                      >
                                        {item.field}
                                      </FormLabel>

                                      <Field
                                        as={Input}
                                        placeholder={item?.hint}
                                        id={item?.key}
                                        name={item?.key}
                                        type={item?.type}
                                        validate={(value: any) => {
                                          let error;

                                          if (
                                            value &&
                                            !/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}|[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)(:[0-9]{1,5})?(\/.*)?$/.test(
                                              value
                                            )
                                          ) {
                                            error =
                                              'Please enter a valid link.';
                                          } else {
                                            if (value === '') {
                                              error = 'Field is mandatory.';
                                            }
                                          }

                                          return error;
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {errors?.url}
                                      </FormErrorMessage>
                                    </FormControl>
                                  ) : item.key === InputFieldType.workspaces ? (
                                    <FormControl
                                      mb="10px"
                                      isInvalid={
                                        !!errors.workspaces &&
                                        touched.workspaces
                                      }
                                    >
                                      <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="text.secondary"
                                        lineHeight="24px"
                                      >
                                        {item.field}
                                      </FormLabel>

                                      <Field
                                        as={Input}
                                        placeholder={item?.hint}
                                        id={item?.key}
                                        name={item?.key}
                                        type={item?.type}
                                        validate={(value: any) => {
                                          let error;

                                          if (value === '') {
                                            error = 'Field is mandatory';
                                          }

                                          return error;
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {errors?.workspaces}
                                      </FormErrorMessage>
                                    </FormControl>
                                  ) : item.key === InputFieldType.token ? (
                                    <FormControl
                                      mb="10px"
                                      isInvalid={
                                        !!errors.token && touched.token
                                      }
                                    >
                                      <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="text.secondary"
                                        lineHeight="24px"
                                      >
                                        {item.field}
                                      </FormLabel>

                                      <Field
                                        as={Input}
                                        placeholder={item?.hint}
                                        id={item?.key}
                                        name={item?.key}
                                        type={item?.type}
                                        validate={(value: any) => {
                                          let error;

                                          if (value === '') {
                                            error = 'Field is mandatory.';
                                          }

                                          return error;
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {errors?.token}
                                      </FormErrorMessage>
                                    </FormControl>
                                  ) : item.key === InputFieldType.password ? (
                                    <FormControl
                                      mb="10px"
                                      isInvalid={
                                        !!errors.password && touched.password
                                      }
                                    >
                                      <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="text.secondary"
                                        lineHeight="24px"
                                      >
                                        {item.field}
                                      </FormLabel>

                                      <Field
                                        as={Input}
                                        placeholder={item?.hint}
                                        id={item?.key}
                                        name={item?.key}
                                        type={item?.type}
                                        validate={(value: any) => {
                                          let error;

                                          if (value === '') {
                                            error = 'Field is mandatory.';
                                          }

                                          return error;
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {errors?.password}
                                      </FormErrorMessage>
                                    </FormControl>
                                  ) : item.key === InputFieldType.username ? (
                                    <FormControl
                                      mb="10px"
                                      isInvalid={
                                        !!errors.username && touched.username
                                      }
                                    >
                                      <FormLabel
                                        fontSize="sm"
                                        fontWeight="medium"
                                        color="text.secondary"
                                        lineHeight="24px"
                                      >
                                        {item.field}
                                      </FormLabel>

                                      <Field
                                        as={Input}
                                        placeholder={item?.hint}
                                        id={item?.key}
                                        name={item?.key}
                                        type={item?.type}
                                        validate={(value: any) => {
                                          let error;

                                          if (value === '') {
                                            error = 'Field is mandatory.';
                                          }

                                          return error;
                                        }}
                                      />
                                      <FormErrorMessage>
                                        {errors?.username}
                                      </FormErrorMessage>
                                    </FormControl>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            <Button
                              type="submit"
                              w="full"
                              mt={'4'}
                              variant="tertiary"
                            >
                              {`Integrate ${intDetailsData?.dto?.name}`}
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </MiddleSection>
                  )}

                {(intDetailsData?.dto?.fields === null ||
                  intDetailsData?.dto?.fields?.length < 1) && (
                  <BottomSection>
                    <Button
                      type="submit"
                      w="full"
                      mt={'4'}
                      variant="tertiary"
                      onClick={() => {
                        window.analytics.track('integration_created', {
                          properties: {
                            integration: `${intDetailsData?.dto?.name}`,
                            status: 'init',
                          },
                        });
                        goToLink(intDetailsData?.dto?.links?.oauth_url);
                        setCompleteIntegration((prev) => !prev);
                      }}
                    >
                      {`Integrate ${intDetailsData?.dto?.name}`}
                    </Button>
                  </BottomSection>
                )}
                {(loading || !intDetailsData) && <Loader />}
              </>
            )}
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default IntegrationModal;
