import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import {
  getTeamListGraphql,
  getContributorsList,
  getCodeVelocityList,
  getSprintVelocityList,
  getTeamCollaborationList,
  getPrChartsGraphql,
  goalsGenericGraphql,
  teamStatsGraphql,
  getInProgress,
  doraChartTeamStatsGraphql,
  codeStatsGraphql,
} from './query';

export const QUERY_KEYS = {
  teamList: 'teamList',
  contributorsList: 'contributorsList',
  codeVelocityList: 'codeVelocityList',
  sprintVelocityList: 'sprintVelocityList',
  teamCollaborationList: 'teamCollaborationList',
  prChartsGraphql: 'prChartsGraphql',
  goalsMetric: 'goalsMetric',
  inProgress: 'inProgress',
  teamStats: 'teamStats',
  doraChart: 'doraChart',
  codeStats: 'codeStats',
};

export const usePrChartsGraphql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  metric: string,
  teamId: string,
  project: string,
  searchTerm: string,
  author: string,
  reviewer: string,
  pageNumber: number,
  pageSize: number
) => {
  return useQuery(
    [
      QUERY_KEYS.prChartsGraphql,
      startDate,
      endDate,
      sprintId,
      metric,
      teamId,
      project,
      searchTerm,
      author,
      reviewer,
      pageNumber,
      pageSize,
    ],
    () =>
      getPrChartsGraphql(
        startDate,
        endDate,
        sprintId,
        metric,
        teamId,
        project,
        searchTerm,
        author,
        reviewer,
        pageNumber,
        pageSize
      ),
    {
      keepPreviousData: true,
      select: (data) => data.IndividualCollaborationDetail,
      enabled: !!startDate && !!endDate,
    }
  );
};

export const useFetchTeamsGql = (
  metric: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageSize: number,
  searchTerm: string
) => {
  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery<any>(
      [
        QUERY_KEYS.teamList,
        metric,
        startDate,
        endDate,
        sprintId,
        teamId,
        searchTerm,
      ],
      async ({ pageParam = 0 }) => {
        const data = await getTeamListGraphql(
          metric,
          startDate,
          endDate,
          sprintId,
          teamId,
          pageParam,
          pageSize,
          searchTerm
        );
        return data;
      },
      {
        getNextPageParam: (lastPage, pages) => {
          if (lastPage.TeamDetails?.data?.length < pageSize) {
            return undefined;
          } else {
            return lastPage.TeamDetails?.currentPage + 1;
          }
        },
        keepPreviousData: true,
        staleTime: 300000,
        select: (data) => {
          return {
            ...data,
            pages: data.pages.flatMap((page) => page.TeamDetails.data),
            itemMeta: data.pages[0]?.TeamDetails.itemMeta || [],
            totalPages: data.pages[0]?.TeamDetails.totalPages || 1,
          };
        },
        enabled: !!startDate && !!endDate,
      }
    );

  return { data, isFetching, isLoading, fetchNextPage, hasNextPage };
};

export const useFetchContributorsGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number,
  project: string,
  searchTerm: string
) => {
  return useQuery(
    [
      QUERY_KEYS.contributorsList,
      startDate,
      endDate,
      sprintId,
      pageNumber,
      pageSize,
      teamId,
    ],
    () =>
      getContributorsList(
        startDate,
        endDate,
        sprintId,
        teamId,
        pageNumber,
        pageSize,
        project,
        searchTerm
      ),
    {
      keepPreviousData: true,
      select: (data) => data.ContributorsForDate,
      enabled: !!teamId && !!startDate && !!endDate,
    }
  );
};

export const useFetchInProgressGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  project: string,
  searchTerm: string,
  reviewer: string,
  author: string,
  type: string,
  status: string,
  pageNumber: number,
  pageSize: number
) => {
  return useQuery(
    [
      QUERY_KEYS.inProgress,
      startDate,
      pageNumber,
      pageSize,
      endDate,
      sprintId,
      teamId,
    ],
    () =>
      getInProgress(
        startDate,
        endDate,
        sprintId,
        teamId,
        project,
        searchTerm,
        reviewer,
        author,
        type,
        status,
        pageNumber,
        pageSize
      ),
    {
      keepPreviousData: true,
      select: (data) => data.InProgressItems,
      enabled: !!teamId && !!startDate && !!endDate,
    }
  );
};

export const useFetchSprintVelocityGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
) => {
  return useQuery(
    [
      QUERY_KEYS.sprintVelocityList,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    ],
    () =>
      getSprintVelocityList(
        startDate,
        endDate,
        sprintId,
        teamId,
        pageNumber,
        pageSize
      ),
    {
      keepPreviousData: true,
      select: (data) => data.SprintActivityByOrg,
      enabled: !!teamId && !!startDate && !!endDate,
    }
  );
};

export const useFetchTeamCollaborationGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number
) => {
  return useQuery(
    [
      QUERY_KEYS.teamCollaborationList,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    ],
    () =>
      getTeamCollaborationList(
        startDate,
        endDate,
        sprintId,
        teamId,
        pageNumber,
        pageSize
      ),
    {
      keepPreviousData: true,
      select: (data) => data,
      enabled: !!teamId && !!startDate && !!endDate,
    }
  );
};

export const useFetchCodeVelocityGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  teamId: string,
  pageNumber: number,
  pageSize: number,
  project: string,
  searchTerm: string
) => {
  return useQuery(
    [
      QUERY_KEYS.codeVelocityList,
      startDate,
      endDate,
      sprintId,
      teamId,
      pageNumber,
      pageSize,
    ],
    () =>
      getCodeVelocityList(
        startDate,
        endDate,
        sprintId,
        teamId,
        pageNumber,
        pageSize,
        project,
        searchTerm
      ),
    {
      keepPreviousData: true,
      select: (data) => data.RepositoryActivitiesForDate,
      enabled: !!teamId && !!startDate && !!endDate,
    }
  );
};

export const useGoalsChartGql = (
  metric: string,
  startDate: string,
  endDate: string,
  sprintId: string,
  selectedTeam: string
) => {
  return useQuery(
    [
      QUERY_KEYS.goalsMetric,
      metric,
      startDate,
      endDate,
      sprintId,
      selectedTeam,
    ],
    () =>
      goalsGenericGraphql(metric, startDate, endDate, sprintId, selectedTeam),
    {
      select: (data) => ({
        ...data.GoalMetricsByType,
        data: JSON.parse(data.GoalMetricsByType.data),
      }),

      enabled: !!metric && !!selectedTeam && !!startDate && !!endDate,
    }
  );
};

export const useTeamStatGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  selectedTeam: string,
  type: string
) => {
  return useQuery(
    [QUERY_KEYS.teamStats, startDate, endDate, sprintId, selectedTeam, type],
    () => teamStatsGraphql(startDate, endDate, sprintId, selectedTeam, type),
    {
      select: (data) => data?.TeamStats,
      enabled: !!selectedTeam && !!startDate && !!endDate,
    }
  );
};

export const useDoraChartTeamStatGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  selectedTeam: string
) => {
  return useQuery(
    [QUERY_KEYS.doraChart, startDate, endDate, sprintId, selectedTeam],
    () => doraChartTeamStatsGraphql(startDate, endDate, sprintId, selectedTeam),
    {
      select: (data) => data?.TeamStats,
      enabled: !!selectedTeam && !!startDate && !!endDate,
    }
  );
};

export const useCodeStatsGql = (
  startDate: string,
  endDate: string,
  sprintId: string,
  selectedTeam: string
) => {
  return useQuery(
    [QUERY_KEYS.codeStats, startDate, endDate, sprintId, selectedTeam],
    () => codeStatsGraphql(startDate, endDate, sprintId, selectedTeam),
    {
      select: (data) => data?.TeamStats,
      enabled: !!selectedTeam && !!startDate && !!endDate,
    }
  );
};
