import * as ENDPOINTS from './endpoints';
import { useQuery } from '@tanstack/react-query';

export const QUERY_KEYS = {
  customField: 'customField',
};

export const useCustomfield = () => {
  return useQuery([QUERY_KEYS.customField], ENDPOINTS.getCustomField);
};
