/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  Flex,
  Image,
  Progress,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { BsArrowRight } from 'react-icons/bs';
import {
  AppTooltip,
  PeriodType,
  splitTime,
} from '@devd-client/devd/components';
import { useInsightMetricGql } from '../../../graphql';
import { beforeStyles, hoverBeforeStyles } from '../InsightCards.utils';
import { Link as NavLink } from 'react-router-dom';

interface StackCycleTimeCardProps {
  team: string;
  selected: PeriodType;
}

const readings = [
  { label: 'Waiting Time', content: 0, value: 0, colorScheme: 'green' },
  { label: 'Coding Time', content: 0, value: 0, colorScheme: 'cyan' },
  { label: 'PR Pickup Time', content: 0, value: 0, colorScheme: 'yellow' },
  { label: 'PR Merge Time', content: 0, value: 0, colorScheme: 'teal' },
  { label: 'Deploy Time', content: 0, value: 0, colorScheme: 'blue' },
];

export const StackCycleTimeCard: React.FC<StackCycleTimeCardProps> = ({
  team,
  selected,
}) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const { data, isLoading } = useInsightMetricGql(
    'CYCLE_TIME_ALL',
    team,
    selected.startDate,
    selected.endDate,
    selected?.sprintId
  );

  const [stepperData, setStepperData] =
    useState<
      { label: string; content: number; value: number; colorScheme: string }[]
    >(readings);

  useEffect(() => {
    if (!data || !data?.data) {
      setStepperData(readings);
    } else {
      setStepperData(
        readings?.map((item: any) => {
          const findPercent = (value: number): number => {
            const backlogTime = Number(data?.data[0]?.backlog_time) || 0;
            const codingTime = Number(data?.data[0]?.coding_time) || 0;
            const mergeTime = Number(data?.data[0]?.merge_time) || 0;
            const pickupTime = Number(data?.data[0]?.pickup_time) || 0;
            const deployTime = Number(data?.data[0]?.deploy_time) || 0;

            const total =
              backlogTime + codingTime + mergeTime + pickupTime + deployTime;

            if (total === 0) {
              return 0;
            }

            return (value / total) * 100;
          };

          return {
            ...item,
            content:
              item?.label === 'Waiting Time'
                ? findPercent(data?.data[0]?.backlog_time)?.toFixed(4)
                : item?.label === 'Coding Time'
                ? findPercent(data?.data[0]?.coding_time)?.toFixed(4)
                : item?.label === 'PR Merge Time'
                ? findPercent(data?.data[0]?.merge_time)?.toFixed(4)
                : item?.label === 'PR Pickup Time'
                ? findPercent(data?.data[0]?.pickup_time)?.toFixed(4)
                : item?.label === 'Deploy Time'
                ? findPercent(data?.data[0]?.deploy_time)?.toFixed(4)
                : 0,
            value:
              item?.label === 'Waiting Time'
                ? data?.data[0]?.backlog_time
                : item?.label === 'Coding Time'
                ? data?.data[0]?.coding_time
                : item?.label === 'PR Merge Time'
                ? data?.data[0]?.merge_time
                : item?.label === 'PR Pickup Time'
                ? data?.data[0]?.pickup_time
                : item?.label === 'Deploy Time'
                ? data?.data[0]?.deploy_time
                : 0,
          };
        })
      );
    }
  }, [data?.data]);

  return (
    <>
      {isLoading ? (
        <Skeleton h="248px" w="100%" />
      ) : data?.chartDataState === 'NO_INTEGRATION' ? (
        <Box
          display="flex"
          justifyContent={'center'}
          alignItems="center"
          position="relative"
          minH="248px"
          border="1px solid #E3E6EA"
          borderRadius="4px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Image
            height="100%"
            src={data?.chartMetadata?.zsImage || ''}
            style={{ position: 'relative' }}
          />
          {isHovered && (
            <Box
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius="4px"
            >
              {data?.chartMetadata?.source && (
                <Button
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  onClick={() => navigate('/settings/integrations')}
                >
                  Connect{' '}
                  {data?.chartMetadata?.source === 'ISSUE TRACKING'
                    ? 'Jira'
                    : data?.chartMetadata?.source === 'REPOSITORY'
                    ? 'Github'
                    : ''}
                </Button>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <Flex
          direction="column"
          p={3}
          pb={10}
          w="full"
          minW="230px"
          minH="370px"
          borderRadius="4px"
          border="1px solid #dde0e4"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
        >
          <Flex direction="column" mb={4}>
            <Flex align="center" justify="space-between">
              <Text
                fontWeight="semibold"
                fontFamily="heading"
                color="text.primary"
                lineHeight="24px"
              >
                {data?.chartMetadata?.chartTitle}
              </Text>
              {data?.chartDataState !== 'ERROR' &&
                data?.chartDataState !== 'NOT_CONFIGURED' && (
                  <Flex
                    as={NavLink}
                    to={`/metrics/cycle-time?${
                      window.location.href.split('?')[1]
                    }`}
                    align="center"
                    justify="space-between"
                    color="text.secondary"
                    cursor="pointer"
                    position="relative"
                    fontSize="sm"
                    _hover={{ _before: hoverBeforeStyles }}
                    _before={beforeStyles}
                  >
                    <Text
                      fontSize="xs"
                      fontWeight="semibold"
                      transition="all 300ms linear"
                      mr={1}
                    >
                      View Details
                    </Text>
                    <BsArrowRight />
                  </Flex>
                )}

              {(data?.chartDataState === 'ERROR' ||
                data?.chartDataState === 'NOT_CONFIGURED') && (
                <AppTooltip
                  trigger="hover"
                  content={
                    <Flex>
                      <Text
                        color="text.primary"
                        fontFamily="heading"
                        fontSize="sm"
                        mr={1}
                      >
                        {data?.chartError?.message}
                      </Text>
                      <NavLink to={`${data?.chartError?.link}`}>
                        <Text
                          color="primary"
                          fontFamily="heading"
                          fontSize="sm"
                          textDecoration={'underline'}
                        >
                          Configure
                        </Text>
                      </NavLink>
                    </Flex>
                  }
                >
                  <Box>
                    <Button
                      size={'xs'}
                      color={'error'}
                      border={'1px'}
                      borderColor={'error'}
                      backgroundColor={'white'}
                      fontFamily="heading"
                    >
                      {'why no data?'}
                    </Button>
                  </Box>
                </AppTooltip>
              )}
            </Flex>
            <Text
              fontSize="xs"
              color="text.secondary"
              fontWeight="normal"
              lineHeight="16px"
              fontFamily="heading"
            >
              {data?.chartMetadata?.info}
            </Text>
          </Flex>

          <Flex flexDir={'column'} justifyContent={'space-between'} h="full">
            {data?.chartDataState !== 'ERROR' &&
              data?.chartDataState !== 'NOT_CONFIGURED' &&
              stepperData?.map((item: any, idx: number) => (
                <Box key={idx}>
                  <Flex
                    justifyContent={'space-between'}
                    color="text.primary"
                    lineHeight="24px"
                    fontSize="xs"
                    fontFamily="heading"
                    fontWeight="medium"
                    pt={2}
                  >
                    <Text>{item?.label}</Text>
                    <Text>{splitTime(Number(item?.value) / 60)}</Text>
                  </Flex>
                  <Progress
                    value={Number(item?.content)}
                    colorScheme={item?.colorScheme}
                    size="md"
                  />
                  <Progress
                    value={Number(item?.content)}
                    colorScheme={item?.colorScheme}
                    size="md"
                  />
                </Box>
              ))}
          </Flex>
        </Flex>
      )}
    </>
  );
};
