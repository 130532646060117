import { apiClient } from '@devd-client/api';
import { CfrResponse, ReleaseResponse } from '../helpers/dora.types';

export const getCfr = (): Promise<CfrResponse> =>
  apiClient(`/v1/dora/org/${localStorage.getItem('orgId')}/cfr`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const addUpdateCfr = (params: any): Promise<any> => {
  return apiClient(`/v1/dora/org/${localStorage.getItem('orgId')}/cfr`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params.cfrData),
  });
};

export const getCustomField = (): Promise<any> =>
  apiClient(
    `/v1/integration/org/${localStorage.getItem('orgId')}/customFields`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );

export const getRelease = (): Promise<ReleaseResponse> =>
  apiClient(`/v1/dora/org/${localStorage.getItem('orgId')}/release`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const addUpdateRelease = (params: any): Promise<any> => {
  return apiClient(`/v1/dora/org/${localStorage.getItem('orgId')}/release`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params.releaseData),
  });
};
