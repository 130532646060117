import { useQuery } from '@tanstack/react-query';
import * as ENDPOINTS from './endpoints';
import { useCustomMutation } from '@devd-client/devd/components';

export const QUERY_KEYS = {
  cfr: 'cfr',
  release: 'release',
  customfield: 'customfield',
};

export const useCfr = () => {
  return useQuery([QUERY_KEYS.cfr], ENDPOINTS.getCfr);
};

export const useAddUpdateCfrMutation = () => {
  return useCustomMutation([QUERY_KEYS.cfr], ENDPOINTS.addUpdateCfr);
};

export const useCustomfield = () => {
  return useQuery([QUERY_KEYS.customfield], ENDPOINTS.getCustomField);
};

export const useRelease = () => {
  return useQuery([QUERY_KEYS.release], ENDPOINTS.getRelease);
};

export const useAddUpdateReleaseMutation = () => {
  return useCustomMutation([QUERY_KEYS.release], ENDPOINTS.addUpdateRelease);
};
