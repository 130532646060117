import { FC, useEffect } from 'react';
import { Box, Flex, Icon, useDisclosure } from '@chakra-ui/react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import AddMetricModal from './AddMetricModal';

interface AddMetricsProps {
  title: string;
  modalHeading: string;
  name?: string;
  queryKeys?: any;
  hideBtn?: boolean;
  buttonText?: string;
  bodyContent?: React.ReactNode;
  openAddMetric?: boolean;
  setOpenAddMetric?: (openAddMetric: boolean) => void;
  forTeam?: boolean;
  hideCreateMetricBtn?: boolean;
}

export const AddMetrics: FC<AddMetricsProps> = ({
  title,
  modalHeading,
  name,
  queryKeys,
  hideBtn,
  buttonText,
  bodyContent,
  openAddMetric,
  setOpenAddMetric,
  forTeam,
  hideCreateMetricBtn,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (openAddMetric) {
      onOpen();
    }
  }, [openAddMetric, onOpen]);

  return (
    <>
      {!openAddMetric && (
        <Box
          cursor={'pointer'}
          minWidth={'350px'}
          minHeight={'400px'}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          border={'1px dashed gray'}
          borderRadius={'4px'}
          borderWidth={'2.5px'}
          p={4}
          onClick={onOpen}
        >
          <Flex align="center" mb={2}>
            <Icon as={AiOutlinePlusCircle} boxSize={6} color="gray" mr={2} />
            <Box fontWeight="bold" fontSize="lg" color="gray">
              {title}
            </Box>
          </Flex>
        </Box>
      )}

      {isOpen && (
        <AddMetricModal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            if (setOpenAddMetric) setOpenAddMetric(false);
          }}
          hideCreateMetricBtn={hideCreateMetricBtn}
          hideBtn={hideBtn}
          modalHeading={modalHeading}
          bodyContent={bodyContent}
          name={name}
          queryKeys={queryKeys}
          buttonText={buttonText}
          forTeam={forTeam}
        />
      )}
    </>
  );
};
