import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import {
  LOC_VS_PR_CYCLE_TIME_CHARTS,
  splitTime,
  WORK_BREAKDOWN_CHARTS,
} from '../shared/utils';
import { useCallback, useState } from 'react';
import MetricSidebar from './components/MetricSidebar';
import moment from 'moment';

interface StackedBarChartRechartsProps {
  data: any;
  keys?: any;
  chartMetadata?: any;
  hideBtn?: boolean;
  repos: any;
  selectedTeam: string;
  selected?: any;
  slant?: boolean;
  granularSlant?: boolean;
}

export const StackedBarChartRecharts = ({
  data,
  keys,
  chartMetadata,
  hideBtn,
  repos,
  selectedTeam,
  selected,
  slant,
  granularSlant,
}: StackedBarChartRechartsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 100,
            left: 20,
            bottom: 30,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={chartMetadata.xAxis}
            axisLine={false}
            tickLine={false}
            label={{
              value: chartMetadata.xlabel,
              position: 'insideBottom',
              offset: -20,
              fontSize: 11,
              fontWeight: 400,
            }}
            style={{
              fontSize: 7.5,
              fontWeight: 600,
              cursor: slant ? 'pointer' : undefined,
            }}
            angle={granularSlant || slant ? -60 : undefined}
            interval={granularSlant || slant ? 0 : undefined}
            textAnchor={granularSlant || slant ? 'end' : 'middle'}
          />
          <YAxis
            yAxisId="left"
            label={{
              value: chartMetadata.ylabel,
              angle: -90,
              position: 'insideLeft',
              fontSize: 11,
              fontWeight: 600,
              dx: -15,
            }}
            axisLine={false}
            tickLine={false}
            style={{ fontSize: 11, fontWeight: 600 }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: chartMetadata.zlabel,
              angle: 90,
              justifyContent: 'center',
              fontSize: 11,
              fontWeight: 600,
              dx: 40,
            }}
            axisLine={false}
            tickLine={false}
            width={100}
            tickFormatter={(e) => splitTime(e / 24)}
            style={{
              fontSize: 11,
              fontWeight: 600,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          />
          <Tooltip
            content={
              <CustomTooltip
                chartKey={chartMetadata.chartKey}
                handleModalOpen={handleModalOpen}
                setClickedData={setClickedData}
                unit={chartMetadata.zlabel}
                onClickAction={() => {
                  setCurrentChartMeta(chartMetadata);
                }}
                hideBtn={hideBtn}
              />
            }
            wrapperStyle={{ outline: 'none' }}
            position={{ y: -30 }}
            allowEscapeViewBox={{ x: false, y: false }}
          />
          <Legend
            verticalAlign="middle"
            layout="vertical"
            iconType="circle"
            iconSize={11}
            align="right"
            wrapperStyle={{
              right: -8,
              fontSize: 11.5,
              paddingRight: 100,
              transform: 'translateY(-50%)',
            }}
          />

          {keys?.map((el: any) =>
            el.chartType === 'bar_chart' ? (
              <Bar
                key={el.key}
                yAxisId="left"
                dataKey={el.key}
                name={el.name}
                barSize={20}
                fill={el.color}
              />
            ) : el.chartType === 'stacked_bar_chart' ? (
              <Bar
                key={el.key}
                dataKey={el.key}
                yAxisId={
                  chartMetadata.chartKey === 'WORK_BREAKDOWN' ? 'left' : 'right'
                }
                name={el.name}
                barSize={20}
                fill={el.color}
                stackId="a"
                style={{
                  cursor:
                    chartMetadata.chartKey === 'WORK_BREAKDOWN'
                      ? 'pointer'
                      : 'default',
                }}
                onClick={(event: any, payload: any) => {
                  if (chartMetadata.chartKey === 'WORK_BREAKDOWN') {
                    handleModalOpen();
                    setCurrentChartMeta(chartMetadata);
                    setClickedData({
                      payload: {
                        startDate: event.startDate,
                        endDate: event.endDate,
                      },
                      name: event.name,
                    });
                  }
                }}
              />
            ) : (
              <></>
            )
          )}
        </BarChart>
      </ResponsiveContainer>
      {[...LOC_VS_PR_CYCLE_TIME_CHARTS, ...WORK_BREAKDOWN_CHARTS].includes(
        currentChartMeta?.chartKey
      ) &&
        isOpen && (
          <MetricSidebar
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={chartMetadata.chartTitle}
            subHeading={
              chartMetadata.chartKey === 'WORK_BREAKDOWN'
                ? `${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.startDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.startDate).format('DD MMM')
                  } - ${
                    Array.isArray(clickedData)
                      ? moment(clickedData[0]?.payload?.endDate).format(
                          'DD MMM'
                        )
                      : moment(clickedData?.payload?.endDate).format('DD MMM')
                  }`
                : `${moment(selected?.startDate).format('DD MMM')} - ${moment(
                    selected?.endDate
                  ).format('DD MMM')}`
            }
            payload={
              chartMetadata.chartKey === 'WORK_BREAKDOWN'
                ? clickedData
                : {
                    ...clickedData,

                    payload: {
                      startDate: selected?.startDate,
                      endDate: selected?.endDate,
                    },
                  }
            }
            currentChartMeta={currentChartMeta}
            filterBy={
              chartMetadata.chartKey === 'WORK_BREAKDOWN'
                ? {
                    teamId: selectedTeam,
                    sprintId: selected.sprintId,
                    projectsFilter: repos,
                  }
                : {
                    teamId: selectedTeam,
                    sprintId: selected.sprintId,
                    lowerBoundIncl: clickedData[0]?.payload?.loc.includes('+')
                      ? Number(clickedData[0]?.payload?.loc?.split('+')[0])
                      : clickedData[0]?.payload?.loc?.split('-')[0] &&
                        Number(clickedData[0]?.payload?.loc?.split('-')[0]),
                    upperBound: clickedData[0]?.payload?.loc.includes('+')
                      ? null
                      : clickedData[0]?.payload?.loc?.split('-')[1] &&
                        Number(clickedData[0]?.payload?.loc?.split('-')[1]),
                  }
            }
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
