import { Box, Button, Flex, Text, Textarea, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import Loader from '../appLoader';
import Breadcrumbs from '../appBreadcrumb';
import {
  QUERY_KEYS,
  useFetchIntegrationGitDetails,
  useGitExcludeBranchesMutation,
} from './api';
import { useQueryClient } from '@tanstack/react-query';
import { useToastHook } from '../appToast';

export const IntegrationGitDetails = () => {
  const { name, id2 } = useParams();
  const [loading, setLoading] = useState(false);
  const [newToast] = useToastHook();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const integrationName = decodeURIComponent(pathname.split('/')[3]);

  const { data: intDetailsData, isFetching } = useFetchIntegrationGitDetails(
    id2 as string,
    integrationName
  );

  const [branchValue, setBranchValue] = useState<string>('');
  const [prValue, setPrValue] = useState<string>('');
  const [label, setLabel] = useState<string>('');

  const handleBranchValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setBranchValue(event?.target?.value);
  };
  const handlePrValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPrValue(event?.target?.value);
  };

  const handleLabelChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLabel(event?.target?.value);
  };

  useEffect(() => {
    if (intDetailsData?.dto?.exclusion?.branches) {
      setBranchValue(intDetailsData?.dto?.exclusion?.branches);
    } else {
      setBranchValue('');
    }
    if (intDetailsData?.dto?.exclusion?.prs) {
      setPrValue(intDetailsData?.dto?.exclusion?.prs);
    } else {
      setPrValue('');
    }
    if (intDetailsData?.dto?.exclusion?.label) {
      setLabel(intDetailsData?.dto?.exclusion?.label);
    } else {
      setLabel('');
    }
  }, [intDetailsData]);

  const { mutate: handleExclusion } = useGitExcludeBranchesMutation();

  const handleSaveClick = (
    branchValue: any,
    prValue: any,
    label: any,
    id2: string
  ) => {
    setLoading(true);

    const formattedBranchValue = branchValue
      ?.replace(/,\n|,\s*|\n+/g, ',')
      ?.replace(/,+$/, '')
      ?.replace(/,+/g, ',')
      ?.trim();

    const formattedPrValue = prValue
      ?.replace(/,\n|,\s*|\n+/g, ',')
      ?.replace(/,+$/, '')
      ?.replace(/,+/g, ',')
      ?.trim();

    const formattedLabel = label
      ?.replace(/,\n|,\s*|\n+/g, ',')
      ?.replace(/,+$/, '')
      ?.replace(/,+/g, ',')
      ?.trim();

    handleExclusion(
      {
        data: {
          exclusion: {
            branches: formattedBranchValue,
            prs: formattedPrValue,
            label: formattedLabel,
          },
        },
        id2,
        integrationName,
      },
      {
        onSuccess: () => {
          setLoading(false);
          queryClient?.invalidateQueries([QUERY_KEYS.intDetails]);

          newToast({
            message: `Branches and PR titles updated`,
            status: 'success',
          });
          setTimeout(
            () =>
              newToast({
                message: `Filter data will reflect in the next 5 minutes.`,
                status: 'info',
              }),
            2000
          );
        },
        onError: (err: any) => {
          newToast({
            message: `${err?.message}` ?? `Failed to save changes`,
            status: 'error',
          });
        },
      }
    );
    setLoading(false);
  };

  return (
    <Box w="full">
      <Box mb={3}>
        <Breadcrumbs />
      </Box>

      <VStack align="stretch" spacing={2}>
        <Text fontFamily={'heading'} fontSize={'lg'} fontWeight={'semibold'}>
          Repository: {name}
        </Text>

        <Text fontSize={'md'} fontFamily={'heading'} fontWeight={'semibold'}>
          Exclude Branches
        </Text>
        <Text fontSize={'sm'} fontFamily={'heading'}>
          Add the names of branches ( comma seperated ) that DevDynamics should{' '}
          <strong>skip </strong>
          when scanning {name} repository.
        </Text>

        <Text fontSize={'sm'} fontFamily={'heading'} color="text.secondary">
          <Text fontWeight="semibold" as="span" color="text.primary">
            Note:
          </Text>{' '}
          If your team work with feature branches you should add branches that
          are not a target to a direct commit and used only as a baseline branch
          (e.g. master, main, stage,dev )
        </Text>

        <Textarea
          value={branchValue}
          onChange={handleBranchValueChange}
          placeholder="Enter Git Branches Name"
          h="70px"
          maxW={'60%'}
        />

        <Text
          fontSize={'md'}
          pt={2}
          fontFamily={'heading'}
          fontWeight={'semibold'}
        >
          Exclude by PR title's
        </Text>
        <Text fontSize={'sm'} fontFamily={'heading'}>
          Add words ( comma separated ) contains in Pull request title that
          DevDynamics should <strong>skip </strong>
          when scanning {name} repository.
        </Text>

        <Textarea
          value={prValue}
          onChange={handlePrValueChange}
          placeholder="Enter PR Title's"
          h="70px"
          maxW={'60%'}
        />

        <Text
          fontSize={'md'}
          pt={2}
          fontFamily={'heading'}
          fontWeight={'semibold'}
        >
          Exclude by Label's
        </Text>
        <Text fontSize={'sm'} fontFamily={'heading'}>
          Add Labels (comma separated) that DevDynamics should{' '}
          <strong>skip </strong>
          when scanning {name} repository.
        </Text>

        <Textarea
          value={label}
          onChange={handleLabelChange}
          placeholder="Enter Label's"
          h="70px"
          maxW={'60%'}
        />

        <Flex>
          <Button
            variant="primary"
            onClick={() => {
              setLoading(true);
              handleSaveClick(branchValue, prValue, label, id2 as string);
            }}
            w={'100px'}
            mt={3}
          >
            Save
          </Button>
        </Flex>
      </VStack>

      {(isFetching || loading) && <Loader />}
    </Box>
  );
};

export default IntegrationGitDetails;
