export interface ModalFormFields {
  url: string;
  workspaces: string;
  token: string;
  password: any;
  username: string;
}

export enum InputFieldType {
  url = 'url',
  workspaces = 'workspaces',
  token = 'token',
  password = 'password',
  username = 'username',
}
