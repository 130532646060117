import { useField, useFormikContext } from 'formik';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from '@chakra-ui/react';

export function TextInput({
  label,
  subLabel,
  inputType,
  leftIcon,
  ...props
}: any) {
  const [field, meta] = useField(props);
  const formikProps = useFormikContext();
  return (
    <FormControl id={props.id} isInvalid={meta.touched && (meta.error as any)}>
      {label && (
        <FormLabel
          fontSize="sm"
          fontFamily="heading"
          fontWeight="semibold"
          color="text.secondary"
          lineHeight="17px"
        >
          {label}
        </FormLabel>
      )}

      {subLabel && (
        <FormLabel
          fontSize="xs"
          fontFamily="heading"
          fontWeight="normal"
          color="text.secondary"
          lineHeight="17px"
        >
          {subLabel}
        </FormLabel>
      )}

      <InputGroup>
        {leftIcon && (
          <InputLeftElement color="text.secondary" children={leftIcon} />
        )}
        {inputType === 'textarea' ? (
          <Textarea
            {...field}
            {...props}
            onBlur={(event) =>
              formikProps.setFieldValue(
                event.target.name,
                event.target.value.trim()
              )
            }
          />
        ) : (
          <Input
            {...field}
            {...props}
            onBlur={(event) =>
              formikProps.setFieldValue(
                event.target.name,
                event.target.value.trim()
              )
            }
          />
        )}
      </InputGroup>

      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
}
export default TextInput;
