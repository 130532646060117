import { FC, useContext, useMemo } from 'react';
import { DataGrid, Paginate } from '@devd-client/devd/components';
import { InitiativeContext } from '../../../../../contexts/initiativesContext';
import styled from 'styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { useSprintGql } from '../../../../../graphql/index';
import { useNavigate } from 'react-router';

const StyledLink = styled.div`
  text-decoration: underline;
  color: #0095e6;
  cursor: pointer;
`;

interface SprintTableProps {
  selectedProjectId: string;
}

const SprintTable: FC<SprintTableProps> = ({ selectedProjectId }) => {
  const navigate = useNavigate();
  const {
    state: { sprint },
    dispatch,
  } = useContext(InitiativeContext);

  const { isFetching: sprintLoading } = useSprintGql(
    selectedProjectId,
    dispatch
  );

  const rows: any[] = useMemo(() => sprint?.sprints || [], [sprint?.sprints]);

  const columnHelper = createColumnHelper<any>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('sprintName', {
        cell: (info) => (
          <StyledLink
            onClick={() =>
              navigate(`/reports/sprint/${info.row.original.sprintId}`)
            }
          >
            {info.getValue()}
          </StyledLink>
        ),
        header: 'Sprint Name',
      }),
      columnHelper.accessor('sprintId', {
        cell: (info) => info.getValue(),
        header: 'Sprint Id',
        size: 80,
      }),
      columnHelper.accessor('project', {
        cell: (info) => info.getValue(),
        header: 'Project',
      }),
      columnHelper.accessor('startDate', {
        cell: (info) => info.getValue(),
        header: 'Start Date',
        size: 100,
      }),
      columnHelper.accessor('endDate', {
        cell: (info) => info.getValue(),
        header: 'End Date',
        size: 100,
      }),
      columnHelper.accessor('commitedTasks', {
        cell: (info) => info.getValue(),
        header: 'Commited Tasks',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('completedTasks', {
        cell: (info) => info.getValue(),
        header: 'Completed Tasks',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('unplannedTasks', {
        cell: (info) => info.getValue(),
        header: 'Unplanned Tasks',
        size: 120,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('commitedSP', {
        cell: (info) => info.getValue(),
        header: 'Commited SP',
        size: 110,
        meta: {
          isNumeric: true,
        },
      }),
      columnHelper.accessor('completedSP', {
        cell: (info) => info.getValue(),
        header: 'Completed SP',
        size: 110,
        meta: {
          isNumeric: true,
        },
      }),
    ],
    [sprint?.sprints]
  );

  return (
    <>
      <DataGrid
        showLoader={sprintLoading}
        columns={columns}
        data={rows}
        maxH="26rem"
        sticky="firstCol"
      />
      {/* <Box marginLeft="-20px">
        <Paginate
          currentPage={taskList?.currentPage + 1}
          totalPageCount={taskList?.totalPages}
          pageSize={10}
          onPageChange={(page) => handleCurrentTaskListPage(page as number)}
        />
      </Box> */}
    </>
  );
};

export default SprintTable;
