import { FC, useMemo, useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { apiClient } from '@devd-client/api';
import { DataGrid } from '../../../GridTable/DataGrid';
import Loader from '../../../appLoader';
import { SlideBar } from '../../../appSlideBar';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #0095e6;
`;

interface ContributorSideBarProps {
  openDrawer: boolean;
  handleDrawerClose: () => void;
  team: string;
  contributorData: any;
  loading: boolean;
  repoTitle?: boolean;
}

const ContributorSideBar: FC<ContributorSideBarProps> = ({
  openDrawer,
  handleDrawerClose,
  team,
  contributorData,
  loading,
  repoTitle,
}) => {
  const rows: any[] = useMemo(
    () => contributorData?.data || [],
    [contributorData?.data]
  );
  const columnHelper = createColumnHelper<any>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => {
          return (
            <StyledLink to={`/contributors/${info?.row?.original?.email}`}>
              {info?.getValue()}
            </StyledLink>
          );
        },
        header: 'NAME',
      }),
      columnHelper.accessor('email', {
        cell: (info) => {
          return (
            <StyledLink to={`/contributors/${info.getValue()}`}>
              {info?.getValue()}
            </StyledLink>
          );
        },
        header: 'EMAIL',
      }),
    ],
    [contributorData?.data]
  );
  const [pending, setPending] = useState(false);
  const download = function (data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window?.URL?.createObjectURL(blob);
    const a = document?.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `${localStorage?.getItem('orgId')} - ${team === '' ? 'All' : team}`
    );
    a.click();
  };
  const downloadCSV = async () => {
    setPending(true);
    apiClient(
      `/v1/download-contributors/org/${localStorage?.getItem(
        'orgId'
      )}?teamId=${team}`,
      {
        method: 'GET',
        headers: {
          authorization: `bearer ${localStorage?.getItem('token')}`,
        },
      }
    )
      .then((res: any) => {
        setPending(false);
        download(res);
      })
      .catch((err) => {
        setPending(false);
      });
  };
  return (
    <SlideBar
      width={'440px'}
      openDrawer={openDrawer}
      handleDrawerClose={handleDrawerClose}
      sideBarHeading={
        repoTitle
          ? `Contributors for selected Repository`
          : `Contributors Details for Team "${team === '' ? 'All' : team}"`
      }
      sideBarSubHeading=""
      content={
        <Flex flexDirection="column">
          <DataGrid
            showLoader={false}
            skeletonLoader={loading}
            columns={columns}
            data={rows}
            maxH="42rem"
            sticky="none"
          />
          {!loading && (
            <Button
              variant="tertiary"
              onClick={downloadCSV}
              mt={2}
              isDisabled={pending}
            >
              Download data
            </Button>
          )}

          {pending && <Loader />}
        </Flex>
      }
    />
  );
};

export default ContributorSideBar;
