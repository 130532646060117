import {
  Box,
  Divider,
  Tooltip as ChakraToolTip,
  Flex,
  Text,
  Icon,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useTeamDetailsStore } from '../../../store/teamDetail';
import { useLocation } from 'react-router';
import {
  ISSUE_CHART_KEYS,
  MetricSidebar,
  PeriodType,
} from '@devd-client/devd/components';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import moment from 'moment';
import { QUERY_KEYS } from '../../../graphql';

interface pieChartProps {
  pieChartStats: any;
  selected: PeriodType;
}

const Colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink'];

export const IncidentPieChart: React.FC<pieChartProps> = ({
  pieChartStats,
  selected,
}) => {
  const { colors } = useTheme();
  const teammodal = useDisclosure();

  const { pathname } = useLocation();

  const [metricsName, setMetricsName] = useState<string>('');
  const [metricType, setMetricType] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  const sortedpieChartStats = [...pieChartStats];
  sortedpieChartStats.sort((a, b) => {
    return parseInt(b.currentData) - parseInt(a.currentData);
  });

  const data02 = sortedpieChartStats?.map((item: any) => ({
    name: item?.chartMetadata?.chartTitle,
    value: parseInt(item?.currentData),
  }));

  return (
    <Box
      w={'100%'}
      borderWidth={1}
      borderColor="gray.300"
      borderRadius="12px"
      py={'20px'}
      bg="#fff"
      minHeight="248px"
      border="1px solid #E3E6EA"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
      transition="all 0.2s"
      _hover={{
        boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
      }}
    >
      <Text
        fontWeight="semibold"
        fontFamily="heading"
        color="text.primary"
        lineHeight="24px"
        fontSize={'md'}
        ml={30}
      >
        Issue Activity
      </Text>

      <Flex direction="row" justify={'space-around'} align="center">
        <Box maxH="150px" w={'25%'} overflowY="auto">
          <Flex direction="column" justifyContent={'space-around'}>
            {sortedpieChartStats?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider orientation="horizontal" borderColor="gray.300" />

                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                >
                  <Text
                    fontFamily="inter"
                    fontWeight="semibold"
                    color="gray.500"
                  >
                    {data?.chartMetadata?.chartTitle}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label="View details"
                        aria-label="View details"
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setMetricType(data?.chartMetadata?.chartTitle);
                            setHeading(data?.chartMetadata?.chartTitle);
                            teammodal.onOpen();
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color="text.primary"
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                ${
                                  data?.previousData?.improved > 0
                                    ? 'improved'
                                    : 'deteriorated'
                                } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
        </Box>

        {[...ISSUE_CHART_KEYS].includes(metricsName) && teammodal?.isOpen && (
          <MetricSidebar
            querykey={QUERY_KEYS.teamStats}
            openDrawer={teammodal?.isOpen}
            handleDrawerClose={teammodal?.onClose}
            heading={heading}
            subHeading={`${moment(selected.startDate).format(
              'DD MMM'
            )} - ${moment(selected.endDate).format('DD MMM')}
           `}
            payload={{
              payload: {
                startDate: selected.startDate,
                endDate: selected.endDate,
              },
            }}
            currentChartMeta={{ chartKey: metricsName }}
            filterBy={{
              teamId: decodeURIComponent(pathname?.split('/')[2]),
              type: metricType,
              sprintId: selected.sprintId,
            }}
            selectedTeam={''}
          />
        )}

        <ResponsiveContainer width="50%" height={200}>
          <PieChart width={200} height={200}>
            <Pie
              data={data02}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={60}
              fill="#82ca9d"
              label
            >
              {data02?.map((entry: any, index: any) => (
                <Cell key={`cell-${index}`} fill={Colors[index % 6]} />
              ))}
            </Pie>
            <Tooltip />
            {/* {data02?.length < 4 && <Legend align="center" iconSize={10}/>} */}
          </PieChart>
        </ResponsiveContainer>
      </Flex>
    </Box>
  );
};

export default IncidentPieChart;
