import {
  Box,
  Divider,
  Tooltip as ChakraToolTip,
  Flex,
  Text,
  Icon,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { useLocation } from 'react-router';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

import moment from 'moment';
import {
  COMMIT_CHART_KEYS,
  WORK_BREAKDOWN_CHARTS,
  WORK_BREAKDOWN_STATS_CHARTS,
} from '../shared/utils';
import { MetricSidebar } from '../charts/components/MetricSidebar';

interface pieChartProps {
  pieChartStats: any;
  selected: any;
  queryKey: string;
}

export const CodeWorkBreakdownPieChart: React.FC<pieChartProps> = ({
  pieChartStats,
  selected,
  queryKey,
}) => {
  const { colors } = useTheme();
  const { pathname } = useLocation();
  const authormodal = useDisclosure();

  const [metricsName, setMetricsName] = useState<string>('');
  const [metricType, setMetricType] = useState<string>('');
  const [heading, setHeading] = useState<string>('');

  const sortedpieChartStats = [...pieChartStats];
  sortedpieChartStats.sort((a, b) => {
    return parseInt(b.currentData) - parseInt(a.currentData);
  });

  const commitStatsTabularData = sortedpieChartStats?.filter((item: any) =>
    [WORK_BREAKDOWN_CHARTS[1], ...COMMIT_CHART_KEYS].includes(item.metric)
  );

  const filteredPieChartStats = sortedpieChartStats
    ?.filter(
      (item: any) =>
        ![WORK_BREAKDOWN_CHARTS[1], ...COMMIT_CHART_KEYS].includes(item.metric)
    )
    ?.map((item: any) => ({
      name: item?.chartMetadata?.chartTitle,
      value: parseInt(item?.currentData),
    }));

  const codeWorkDownStats = sortedpieChartStats
    ?.filter((item: any) =>
      [...WORK_BREAKDOWN_STATS_CHARTS].includes(item.metric)
    )
    ?.map((item: any) => {
      return {
        ...item,
        color:
          item.metric === 'WORK_BREAKDOWN_NEW_WORK'
            ? '#71E03A'
            : item.metric === 'WORK_BREAKDOWN_RE_WORK'
            ? '#FF5230'
            : item.metric === 'WORK_BREAKDOWN_REFACTOR'
            ? '#0095E6'
            : item.metric === 'WORK_BREAKDOWN_HELP_OTHERS' && '#F1B813',
      };
    });

  return (
    <Box
      w={'100%'}
      borderWidth={1}
      borderColor="gray.300"
      borderRadius="12px"
      py={'20px'}
      bg="#fff"
      minHeight="248px"
      border="1px solid #E3E6EA"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.08)"
      transition="all 0.2s"
      _hover={{
        boxShadow: '0 2px 7px 0 rgba(0,0,0,.06)',
      }}
    >
      <Text
        fontWeight="semibold"
        fontFamily="heading"
        color="text.primary"
        lineHeight="24px"
        fontSize={'md'}
        ml={6}
      >
        Code Work Breakdown
      </Text>

      <Flex direction="row" justify={'space-around'} align="top" mt={'2rem'}>
        <Box maxH="200px" w={'25%'} overflowY="auto">
          <Flex direction="column" justifyContent={'space-around'}>
            {codeWorkDownStats?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider orientation="horizontal" borderColor="gray.300" />

                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                >
                  <Text
                    fontFamily="inter"
                    fontWeight="semibold"
                    color="gray.500"
                  >
                    {data?.chartMetadata?.chartTitle}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label="View details"
                        aria-label="View details"
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setMetricType(data?.chartMetadata?.chartTitle);
                            setHeading(data?.chartMetadata?.chartTitle);
                            authormodal.onOpen();
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color={data?.color}
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}%
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                  ${
                                    data?.previousData?.improved > 0
                                      ? 'improved'
                                      : 'deteriorated'
                                  } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
        </Box>

        <ResponsiveContainer width="35%" height={200}>
          <PieChart width={200} height={200}>
            <Pie
              data={filteredPieChartStats}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={60}
              fill="#82ca9d"
              label
            >
              {codeWorkDownStats?.map((item: any, index: any) => (
                <Cell key={`cell-${index}`} fill={item?.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>

        <Box maxH="200px" w={'25%'} overflowY="auto">
          <Flex direction="column" justifyContent={'space-around'}>
            {commitStatsTabularData?.map((data: any, index: number) => (
              <React.Fragment key={index}>
                <Divider orientation="horizontal" borderColor="gray.300" />

                <Flex
                  align="center"
                  justifyContent="space-between"
                  fontSize="sm"
                >
                  <Text
                    fontFamily="inter"
                    fontWeight="semibold"
                    color="gray.500"
                  >
                    {data?.chartMetadata?.chartTitle}
                  </Text>

                  <Flex direction="column" align="flex-end">
                    <Flex align={'center'}>
                      <ChakraToolTip
                        label="View details"
                        aria-label="View details"
                      >
                        <Text
                          cursor="pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setMetricsName(data?.metric);
                            setMetricType(data?.chartMetadata?.chartTitle);
                            setHeading(data?.chartMetadata?.chartTitle);
                            authormodal.onOpen();
                          }}
                          fontFamily="inter"
                          fontWeight="semibold"
                          fontSize="md"
                          color="text.primary"
                          textDecoration="none"
                          transition="text-decoration 0.2s ease-in-out"
                          _hover={{
                            textDecoration: 'underline',
                          }}
                          _focus={{
                            outline: 'none',
                            boxShadow: 'outline',
                          }}
                        >
                          {data?.currentData}
                        </Text>
                      </ChakraToolTip>

                      <Box minW={5} h={4}>
                        {typeof data?.previousData?.percentChange !==
                          'undefined' &&
                          data?.previousData?.percentChange !== 0 &&
                          data?.previousData?.percentChange !== null && (
                            <Flex>
                              <ChakraToolTip
                                label={`${Math.abs(
                                  data?.previousData?.percentChange
                                )}%
                                  ${
                                    data?.previousData?.improved > 0
                                      ? 'improved'
                                      : 'deteriorated'
                                  } compared to the last period `}
                                placement="right"
                                hasArrow
                              >
                                <Box>
                                  <Icon
                                    as={
                                      data?.previousData?.percentChange > 0
                                        ? IoMdArrowDropup
                                        : IoMdArrowDropdown
                                    }
                                    fontSize={'20px'}
                                    color={
                                      data?.previousData?.improved
                                        ? colors?.success
                                        : colors?.error
                                    }
                                  />
                                </Box>
                              </ChakraToolTip>
                            </Flex>
                          )}
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </React.Fragment>
            ))}
          </Flex>
        </Box>

        {[
          ...WORK_BREAKDOWN_CHARTS,
          ...WORK_BREAKDOWN_STATS_CHARTS,
          ...COMMIT_CHART_KEYS,
        ].includes(metricsName) &&
          authormodal?.isOpen && (
            <MetricSidebar
              querykey={queryKey}
              openDrawer={authormodal?.isOpen}
              handleDrawerClose={authormodal?.onClose}
              heading={heading}
              subHeading={`${moment(selected.startDate).format(
                'DD MMM'
              )} - ${moment(selected.endDate).format('DD MMM')}
             `}
              payload={{
                payload: {
                  startDate: selected.startDate,
                  endDate: selected.endDate,
                },
              }}
              currentChartMeta={{ chartKey: 'WORK_BREAKDOWN' }}
              filterBy={{
                author: decodeURIComponent(pathname?.split('/')[2]),
                type: metricType,
                sprintId: selected.sprintId,
              }}
              selectedTeam={''}
            />
          )}
      </Flex>
    </Box>
  );
};

export default CodeWorkBreakdownPieChart;
