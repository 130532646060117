import {
  Box,
  Divider,
  Flex,
  IconButton,
  Tag,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { useAiAssistantStore } from '../../../hooks/aiAssistantState';
import { AiAssistantIcon } from '../../../icons/AiAssistantIcon';
import { splitTime } from '../../../shared/utils';
import { BsArrowRight } from 'react-icons/bs';
import { beforeStyles, hoverBeforeStyles } from '../../metricsCard.styled';
import { useState } from 'react';

interface MetricInfoItemsProps {
  item?: any;
  setType?: any;
  type?: any;
  clickedTab?: string;
  setClickedTab: (clickedTab: string) => void;
}

export const MetricInfoItems = ({
  item,
  setType,
  type,
  clickedTab,
  setClickedTab,
}: MetricInfoItemsProps) => {
  const { colors } = useTheme();

  const { setData, toggleChatbox, isOpen } = useAiAssistantStore();
  const [hovered, setHovered] = useState(false);

  function handleClick(data: any): void {
    const payload = {
      metricName: data?.chartKey,
      metricCount: `${data?.threshold?.value}\tTarget/Goal: ${data?.threshold?.target}`,
      metricTrend: data?.threshold?.desc,
    };
    setData(payload);
    toggleChatbox();
    // Track the event in posthog
    !isOpen &&
      window.analytics.track('ai_assistant', {
        properties: {
          metricName: `${data?.chartMetadata?.chartKey}`,
          orgId: localStorage.getItem('orgId'),
        },
      });
  }

  return (
    <>
      <Box
        borderRadius="4px"
        minWidth="160px"
        cursor="pointer"
        _hover={{
          boxShadow: 'xl',
          transition: 'all 0.2s',
        }}
        border={
          clickedTab === item?.chartKey
            ? '1px solid rgba(0, 0, 0, 0.7)'
            : '1px solid #BBC7DA'
        }
        boxShadow={clickedTab === item?.chartKey ? 'xl' : 'sm'}
        transform={
          clickedTab === item?.chartKey ? 'translateY(-2px)' : 'scale(1)'
        }
        p={3}
        minHeight="100px"
        onClick={() => {
          setType(item?.chartKey);
          setClickedTab(item?.chartKey);
        }}
      >
        <Flex align={'center'} justify={'space-between'}>
          <Box
            cursor="pointer"
            position="relative"
            display="flex"
            alignItems="center"
            mb={0.5}
            fontSize="sm"
            _before={beforeStyles}
            onMouseEnter={() => setHovered((prev) => !prev)}
            onMouseLeave={() => setHovered((prev) => !prev)}
          >
            <Text
              fontFamily="heading"
              fontSize="15px"
              fontWeight="semibold"
              as="span"
              letterSpacing={0}
              transition="all 300ms linear"
              mr={hovered ? 2 : 1}
              color="text.secondary"
            >
              {item?.title}
            </Text>
            <BsArrowRight size={16} color={colors.text.secondary} />
          </Box>
          <IconButton
            aria-label="ticket/open-ai-assistant"
            icon={
              <AiAssistantIcon
                width="25px"
                height="25px"
                color={colors.primary}
              />
            }
            onClick={() => handleClick(item)}
            variant="unstyled"
            size={'xs'}
          />
        </Flex>
        <Flex>
          {item?.threshold ? (
            <Text
              as="span"
              fontSize="xl"
              color="text.primary"
              fontWeight="semibold"
              fontFamily="heading"
            >
              {item?.threshold === null
                ? '_'
                : item?.threshold?.value === 'NaN'
                ? '_'
                : item?.threshold?.value === null
                ? '_'
                : item?.chartKey === 'PR_CYCLE_TIME_BREAKDOWN' ||
                  item?.chartKey === 'LEAD_TIME_BREAKDOWN' ||
                  item?.chartKey === 'MEAN_TIME_TO_RECOVERY_DISTRIBUTION'
                ? splitTime(parseFloat(item?.threshold?.value))
                : String(parseFloat(item?.threshold?.value).toFixed(1))}
            </Text>
          ) : (
            <Text
              as="span"
              fontSize="xl"
              color="text.primary"
              fontWeight="semibold"
              fontFamily="heading"
            >
              _
            </Text>
          )}

          <Text
            mt={2.5}
            as="span"
            fontSize="xxs"
            color="text.secondary"
            fontWeight="semibold"
            fontFamily="heading"
            ml={1}
          >
            {item?.threshold?.title}
          </Text>
        </Flex>
        {item?.threshold?.target && (
          <>
            <Box
              mb={2}
              height="4px"
              width="full"
              bg={item?.threshold?.color}
              borderBottomWidth={0}
            />
            <Flex width={'full'} justify="space-between">
              <Text
                fontSize="xs"
                color="text.secondary"
                fontWeight="normal"
                fontFamily="heading"
              >
                Goal: {item?.threshold?.target}
              </Text>
              {item?.threshold?.tag && (
                <Tag
                  size="sm"
                  bg="gray.100"
                  fontSize="xxs"
                  fontFamily="heading"
                >
                  {item?.threshold?.tag}
                </Tag>
              )}
            </Flex>
          </>
        )}
      </Box>
    </>
  );
};
