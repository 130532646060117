import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  PageHeader,
  MetaTags,
  durationData,
  useQueryState,
  removeEmptyQueryParams,
  PageToolbar,
  TeamMenu,
  ViewSwitcher,
  Loader,
  AppTooltip,
  SlideBar,
  DateFilter,
} from '@devd-client/devd/components';
import { FC, useEffect, useState } from 'react';
import SprintList from '../components/SprintList';
import { useSprintList } from '../graphql';
import { AuthActionTypes, getUser, useAuth } from '@devd-client/devd/auth';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { MdCheckCircle } from 'react-icons/md';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import { ticketTypeFilterOptions } from '../helpers/home.utils';
import { useDashboardStore } from '@devd-client/devd/components';

export const viewSwitchObj = [
  {
    tab: 'Count',
    tooltipText: 'Task Count',
    TabIcon: '',
  },
  {
    tab: 'Points',
    tooltipText: 'Story Point',
    TabIcon: '',
  },
];

const chakraStyles: ChakraStylesConfig = {
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 9999,
    };
  },
};

export const Sprint: FC = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useQueryState('start');
  const [endDate, setEndDate] = useQueryState('end');
  const [duration, setDuration] = useQueryState('duration');
  const [team, setTeam] = useQueryState('team');
  const [sprintId, setSprintId] = useQueryState('sprintId');
  const [sprintName, setSprintName] = useQueryState('sprint');
  const [statusId, setStatusId] = useQueryState('status');
  const [ticket, setTicket] = useQueryState('ticket');

  const [currentPage, setCurrentPage] = useState(0);
  const [appState, dispatch] = useAuth();

  const [sprintIds, setSprintIds] = useState<any>([]);
  const [periodOptions, setPeriodOptions] = useState<any>(durationData);
  const [viewToggle, setViewToggle] = useState<string>(viewSwitchObj[0].tab);
  const [status, setStatus] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const slideDrawer = useDisclosure();
  const [blockedIssues, setBlockedIssues] = useState<any>([]);

  const { selectedTeam, selected, setSelectedTeam, setSelected } =
    useDashboardStore();

  const [ticketType, setTicketType] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const { data, isFetching: sprintListLoading } = useSprintList(
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    sprintIds?.length > 0 ? '' : selected.sprintId,
    selected.duration === 'Sprint' ? sprintIds : [],
    20,
    currentPage,
    viewToggle === 'Points' ? true : false,
    status === false ? '' : 'completed',
    ticketType?.length === 1 ? ticketType[0].value : 'all'
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [
    selected.startDate,
    selected.endDate,
    selectedTeam.value,
    selected.duration,
    viewToggle,
    status,
    ticketType,
  ]);

  useEffect(() => {
    if (typeof selected !== 'undefined') {
      selected.startDate && setStartDate(selected.startDate);
      selected.endDate && setEndDate(selected.endDate);
      selected.duration && setDuration(selected.duration);
      selected?.sprintId === ''
        ? removeEmptyQueryParams({ sprintId: '' })
        : setSprintId(selected?.sprintId);
      selected?.sprintName === ''
        ? removeEmptyQueryParams({ sprint: '' })
        : setSprintName(selected?.sprintName);
    }

    if (typeof selectedTeam !== 'undefined') {
      selectedTeam.label === 'All'
        ? setTeam('All')
        : setTeam(selectedTeam.value);
    }

    if (ticketType) {
      ticketType?.length > 1
        ? setTicket('all')
        : setTicket(ticketType[0]?.value);
    }

    if (typeof status !== 'undefined') {
      status === false
        ? removeEmptyQueryParams({ status: '' })
        : setStatusId('completed');
    }
  }, [selected, selectedTeam.value, status, ticketType]);

  useEffect(() => {
    team &&
      setSelectedTeam(
        team === 'All'
          ? { value: '', label: 'All' }
          : { value: team, label: team }
      );

    statusId && setStatus(true);

    ticket && ticket === 'bug'
      ? setTicketType([ticketTypeFilterOptions[1]])
      : ticket === 'task'
      ? setTicketType([ticketTypeFilterOptions[0]])
      : ticket === 'all'
      ? setTicketType(ticketTypeFilterOptions)
      : setTicketType([]);

    duration || selected.duration
      ? setSelected(
          periodOptions
            .filter(
              (item: any) =>
                item.duration === duration ||
                item.duration === selected.duration
            )
            .map((el: any) =>
              el.duration === 'Custom'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                  }
                : el.duration === 'Sprint'
                ? {
                    ...el,
                    startDate: startDate || selected.startDate,
                    endDate: endDate || selected.endDate,
                    sprintId: sprintId || selected.sprintId,
                    sprintName: sprintName || selected.sprintName,
                  }
                : el
            )[0]
        )
      : appState?.user?.dto?.sprint
      ? setSelected({
          ...periodOptions[6],
          startDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.startDate
          ).format('YYYY-MM-DD'),
          endDate: moment(
            JSON.parse(appState?.user?.dto?.sprintDetail)?.endDate
          ).format('YYYY-MM-DD'),
          sprintId: appState?.user?.dto?.sprint,
          sprintName: JSON.parse(appState?.user?.dto?.sprintDetail)?.sprintName,
        })
      : setSelected(periodOptions[3]);
  }, []);

  return (
    <>
      <MetaTags title="Sprint | Dashboard | Devdynamics" />

      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
        {data?.chartDataState === 'NO_INTEGRATION' ? (
          <Box>
            <PageHeader headingText={'Sprint'} mb={'10px'} />
            <Flex mb={2} width="100%" justify="flex-end">
              <AppTooltip
                trigger="hover"
                content={
                  <Flex>
                    <Link to={`${data?.chartError?.link}`}>
                      <Text
                        color="primary"
                        fontFamily="heading"
                        fontSize="sm"
                        textDecoration={'underline'}
                      >
                        Configure
                      </Text>
                    </Link>
                    <Text
                      fontFamily="heading"
                      fontSize="sm"
                      color="text.primary"
                      ml={2}
                    >
                      {data?.chartError?.message}
                    </Text>
                  </Flex>
                }
              >
                <Box>
                  <Button
                    size={'sm'}
                    color={'error'}
                    border="1px"
                    borderColor={'error'}
                    backgroundColor={'white'}
                    fontFamily="heading"
                  >
                    {'why no data?'}
                  </Button>
                </Box>
              </AppTooltip>
            </Flex>

            <Box
              w="full"
              height="400px"
              display="flex"
              justifyContent={'center'}
              alignItems="center"
              position="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Image
                height="100%"
                src={data?.zs || ''}
                style={{ position: 'relative' }}
              />

              {isHovered && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.4)"
                  borderRadius="4px"
                >
                  {data?.chartError.message && (
                    <Button
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      onClick={() => navigate('/settings/integrations')}
                    >
                      Connect{' '}
                      {data?.chartError.message === 'ISSUE TRACKING'
                        ? 'Jira'
                        : data?.chartError.message === 'REPOSITORY'
                        ? 'Github'
                        : ''}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <PageHeader headingText={'Sprint'} mb={'10px'} />
            <PageToolbar
              leftComp={
                <Flex align="flex-end">
                  <Box mr={3} minW={'160px'}>
                    <Text
                      fontSize="sm"
                      fontFamily="heading"
                      fontWeight="semibold"
                      color="text.secondary"
                      lineHeight="17px"
                      mb={2}
                    >
                      Ticket Type
                    </Text>
                    <Select
                      placeholder="Select Ticket Type"
                      isMulti={true}
                      useBasicStyles
                      chakraStyles={chakraStyles}
                      onChange={(e: any) => {
                        setTicketType(e);
                      }}
                      size="sm"
                      value={ticketType}
                      options={ticketTypeFilterOptions}
                    />
                  </Box>
                  <TeamMenu
                    selectedTeam={selectedTeam}
                    handleSelectedTeam={setSelectedTeam}
                    AuthActionTypes={AuthActionTypes}
                    getUser={getUser}
                    appState={appState}
                    dispatch={dispatch}
                  />
                </Flex>
              }
              rightComp={
                <DateFilter
                  getUser={getUser}
                  appState={appState}
                  dispatch={dispatch}
                  AuthActionTypes={AuthActionTypes}
                  sprintIds={sprintIds}
                  setSprintIds={setSprintIds}
                />
              }
            />

            <PageToolbar
              leftComp={
                <Flex align="flex-end" my={3}>
                  <Box>
                    <ViewSwitcher
                      tabList={viewSwitchObj}
                      currentTab={viewToggle}
                      handleSwitch={setViewToggle}
                    />
                  </Box>

                  <Box ml={2} border="1px solid #E2E8F0" borderRadius={'2px'}>
                    <Checkbox
                      style={{ padding: '7px' }}
                      size="md"
                      isChecked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                    >
                      Completed
                    </Checkbox>
                  </Box>
                </Flex>
              }
            />

            <SprintList
              data={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loading={sprintListLoading}
              team={selectedTeam.value}
              slideDrawer={slideDrawer}
              setBlockedIssues={setBlockedIssues}
            />

            {slideDrawer.isOpen && (
              <SlideBar
                openDrawer={slideDrawer.isOpen}
                handleDrawerClose={slideDrawer.onClose}
                width={'440px'}
                sideBarHeading={'Blocked Issues'}
                sideBarSubHeading=""
                content={
                  <>
                    <Box p="4">
                      <List spacing={3}>
                        {blockedIssues.map((item: any) => (
                          <ListItem key={item}>
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            {item}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  </>
                }
              />
            )}
          </Box>
        )}
      </Box>
      {sprintListLoading && <Loader />}
    </>
  );
};

export default Sprint;
