import { useCallback, useState } from 'react';
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart,
} from 'recharts';
import './chart.css';
import { useNavigate } from 'react-router';
import { REVIEW_RESPONSIVENESS_INDEX_CHARTS } from '../shared/utils';
import MetricSidebar from './components/MetricSidebar';
import CustomTooltip from './components/customTooltip/CustomTooltip';
import { useTheme } from '@chakra-ui/react';
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

interface PercentageChartProps {
  chartref?: any;
  data: any;
  keys?: any;
  chartMetadata?: any;
  showLabel?: boolean;
  selectedTeam: string;
  hideBtn?: boolean;
  slant?: boolean;
  forDetails?: boolean;
  selected?: any;
  repos?: string[];
}

export const PercentageChart = ({
  chartref,
  data,
  keys,
  chartMetadata,
  showLabel,
  selectedTeam,
  hideBtn,
  slant,
  forDetails,
  selected,
  repos,
}: PercentageChartProps) => {
  const { colors } = useTheme();
  const [hoveredLabel, setHoveredLabel] = useState<any>(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [clickedData, setClickedData] = useState<any>(null);
  const [currentChartMeta, setCurrentChartMeta] = useState<any>(null);

  const params = new URLSearchParams(window.location.search);
  const team = params.get('team');

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const handleClick = (label: any) => {
    const labelId = data.find(
      (el: any) => el.xLabelName === label.value
    ).xLabelId;

    navigate(`sprint/${labelId}${team && `?team=${team}`}`);
  };

  const handleMouseEnter = (label: any) => {
    setHoveredLabel(label);
  };

  const handleMouseLeave = () => {
    setHoveredLabel(null);
  };

  const RenderCustomTick = (props: any) => {
    const { x, y, payload } = props;
    const { value } = payload;
    const isHovered = value === hoveredLabel;

    return (
      <>
        <ChakraTooltip
          hasArrow
          label={
            data
              .filter((item: any) => item.name === payload.value)
              .map((el: any) => el.info)[0]
          }
        >
          <g transform={`translate(${x},${y})`}>
            <text
              x={0}
              y={0}
              dy={4}
              dx={-22}
              fontSize="12"
              fill={colors.text.secondary}
              fontWeight={isHovered ? 700 : 600}
              textAnchor="end"
            >
              {value}{' '}
            </text>

            <foreignObject x={-20} y={-8} width={20} height={20}>
              <MdInfoOutline color={colors.text.secondary} />
            </foreignObject>
          </g>
        </ChakraTooltip>
      </>
    );
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 130,
            left: 170,
            bottom: 5,
          }}
          barSize={20}
          layout="vertical"
        >
          <XAxis
            type="number"
            tickLine={false}
            domain={[0, 100]}
            tickFormatter={(value) => `${value}%`}
            style={{ fontSize: 11, fontWeight: 500 }}
          />
          <YAxis
            tickLine={false}
            type="category"
            dataKey="name"
            tick={<RenderCustomTick data={data} />}
          />
          <Tooltip content={<CustomTooltip hideBtn={true} />} />

          <Bar
            dataKey="value"
            fill="#0095E6"
            barSize={48}
            style={{ cursor: 'pointer' }}
            background={{ fill: '#BBBBBC' }}
            onClick={(event: any, payload: any) => {
              handleModalOpen();
              setClickedData({
                name: event.name,
                payload: {
                  startDate: event.startDate,
                  endDate: event.endDate,
                },
              });
              setCurrentChartMeta(chartMetadata);
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      {[...REVIEW_RESPONSIVENESS_INDEX_CHARTS].includes(
        currentChartMeta?.chartKey
      ) &&
        isOpen && (
          <MetricSidebar
            openDrawer={isOpen}
            handleDrawerClose={handleModalClose}
            heading={clickedData.name}
            subHeading={``}
            payload={{
              ...clickedData,

              payload: {
                startDate: selected?.startDate,
                endDate: selected?.endDate,
              },
            }}
            currentChartMeta={currentChartMeta}
            filterBy={{
              teamId: selectedTeam,
              name: clickedData.name,
              projectsFilter: repos,
              sprintId: selected.sprintId,
            }}
            selectedTeam={selectedTeam}
          />
        )}
    </>
  );
};
