import {
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import CodeVelocity from './CodeVelocity';
import Contributors from './Contributors';
import SprintVelocity from './SprintVelocity';
import TeamCollaboration from './TeamCollaboration';
import WorkingAgreement from './WorkingAgreement';
import TeamMetrics from './TeamMetrics';
import { getTabList, getTabs } from '../../helpers/team-details.utils';
import TeamStats from './TeamStats';
import InProgress from './InProgress';
import { ISelect, PeriodType } from '@devd-client/devd/components';

const role = localStorage.getItem('role') || '';
const tabList = getTabList(role);
const tabs = getTabs(role);

interface RightContentProps {
  selectedTab: any;
  setSelectedTab: (selectedTab: any) => void;
  selected: PeriodType;
  selectedTeam: ISelect;
}

const RightContent: React.FC<RightContentProps> = ({
  selectedTab,
  setSelectedTab,
  selected,
  selectedTeam,
}) => {
  const handleTabChange = (index: number) => {
    if (Number.isInteger(index) && Object.values(tabs).includes(index)) {
      setSelectedTab(Object.keys(tabs).find((key) => tabs[key] === index));
    }

    return null;
  };

  return (
    <Stack spacing={6}>
      <Stack spacing={3} width="100%">
        <Tabs width="100%" index={tabs[selectedTab]} onChange={handleTabChange}>
          <TabList>
            {tabList.map((tab: string) => (
              <Tab w="110px" fontSize={'13px'} fontWeight={'semibold'}>
                {tab}
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              {selectedTab === 'teamStats' && <TeamStats selected={selected} />}
            </TabPanel>
            <TabPanel px={0}>
              {selectedTab === 'teamMetrics' && (
                <TeamMetrics selected={selected} />
              )}
            </TabPanel>
            {role !== 'EMPLOYEE' && (
              <TabPanel px={0}>
                {selectedTab === 'contributors' && (
                  <Contributors
                    selected={selected}
                    selectedTeam={selectedTeam}
                  />
                )}
              </TabPanel>
            )}
            <TabPanel px={0}>
              {selectedTab === 'repositories' && (
                <CodeVelocity selected={selected} />
              )}
            </TabPanel>

            <TabPanel px={0}>
              {selectedTab === 'sprint' && (
                <SprintVelocity selected={selected} />
              )}
            </TabPanel>

            <TabPanel px={0}>
              {selectedTab === 'team' && (
                <TeamCollaboration selected={selected} />
              )}
            </TabPanel>

            <TabPanel px={0}>
              {selectedTab === 'workingAgreement' && (
                <WorkingAgreement selected={selected} />
              )}
            </TabPanel>
            <TabPanel px={0}>
              {selectedTab === 'inProgress' && (
                <InProgress selected={selected} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Stack>
  );
};
export default RightContent;
