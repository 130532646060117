import { apiClient } from '@devd-client/api';
import { TeammenuResponse } from '../helpers/teammenu.types';

export const getTeammenu = (): Promise<any> =>
  apiClient(`/v2/team/${localStorage.getItem('orgId')}/lite`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${localStorage.getItem('token')}`,
    },
  });

export const postFavTeam = (params: any) => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/team`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(params),
    }
  );
};

export const deleteFavTeam = (params: any) => {
  return apiClient(
    `/v1/user/${localStorage.getItem('userId')}/preference/team/${params.name}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `bearer ${localStorage.getItem('token')}`,
      },
    }
  );
};
